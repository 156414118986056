import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { storage } from './firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const auth = getAuth();

const AddCourses = ({ closeModal, isEditing, courseData, refreshCourses }) => {
  const [user, setUser] = useState(null);
  const [title, setTitle] = useState(courseData?.title || '');
  const [link, setLink] = useState(courseData?.link || '');
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(courseData?.image || '');
  const [numberOfLessons, setNumberOfLessons] = useState(courseData?.numberOfLessons || '');
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        toastSuccess("User is signed in:", currentUser);
      } else {
        setUser(null);
        toastError("User not signed in");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImageURL(URL.createObjectURL(file));
      setErrors((prevErrors) => ({ ...prevErrors, image: '' })); // Clear image error if any
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageURL(''); // Clears the displayed image URL
  };

  const handleAddCourse = async (event) => {
    event.preventDefault();

    // if (!title || !link || (!image && !imageURL) || !numberOfLessons) {
    //   setErrors({
    //     title: title ? '' : 'Title is required',
    //     link: link ? '' : 'Link is required',
    //     image: image || imageURL ? '' : 'Image is required',
    //     numberOfLessons: numberOfLessons ? '' : 'Number of lessons is required',
    //   });
    //   return;
    // }

    if (!title || !link || (!image && !imageURL) || !numberOfLessons) {
      const errors = {
          title: title ? '' : 'Title is required',
          link: link ? '' : 'Link is required',
          image: image || imageURL ? '' : 'Image is required',
          numberOfLessons: numberOfLessons ? '' : 'Number of lessons is required',
      };
      
      setErrors(errors); // Set errors in the state (if you're displaying them somewhere)

      // Trigger a toast for each missing field
      if (!title) toast.error(errors.title);
      if (!link) toast.error(errors.link);
      if (!image && !imageURL) toast.error(errors.image);
      if (!numberOfLessons) toast.error(errors.numberOfLessons);

      return; // Exit function if there are errors
  }


    try {
      let firebaseUrl = imageURL;

      if (image) {
        const imageRef = ref(storage, `course-images/${image.name}`);
        await uploadBytes(imageRef, image);
        firebaseUrl = await getDownloadURL(imageRef);
      }


      const data = { title, link, image: firebaseUrl, numberOfLessons: Number(numberOfLessons) };

      if (isEditing && courseData) {
        await axios.put(`${process.env.REACT_APP_BASE_URL}/api/courses/${courseData._id}`, data);
      } else {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/courses/add`, data);
      }

      setTitle('');
      setLink('');
      setImage(null);
      setImageURL('');
      setNumberOfLessons('');
      setErrors({});
      closeModal();
      refreshCourses();
    } catch (error) {
      toastError('Error saving course:', error);
    }
  };
  const toastSuccess = (text) => toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  const toastError = (text) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  return (
    <div className="p-4 md:p-8 mt-20 md:mt-0">
      {user ? (
        <>
          <h1 className="text-2xl md:text-[43.49px] leading-[1.2] font-bold mb-4 md:mb-7 text-[#4F4F4F]">Add Courses</h1>
          <p className="mb-6 md:mb-10 font-medium text-lg md:text-[24.46px] leading-[1.3] text-[#4F4F4F]">Manually</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            <div>
              <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2">Course Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setErrors((prev) => ({ ...prev, title: '' }));
                }}
                className={`w-full outline-none border ${errors.title ? 'border-red-500' : 'border-gray-300'} px-3 py-2 md:py-3 rounded-lg`}
              />
              {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
            </div>
            <div>
              <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2">Course Image</label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <input
                  type="text"
                  value={image ? image.name : imageURL ? "Image selected" : ''}
                  readOnly
                  className="flex-grow lg:p-3 p-0 outline-none text-sm md:text-base ml-1 lg:ml-2"
                  placeholder="Choose an image"
                />
                <div
                  className="border-l border-gray-300 p-2 cursor-pointer"
                  onClick={handleImageClick}
                >
                  <img src="Plus.svg" alt="Upload" className="w-7 h-7 md:w-6 md:h-6" />
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="hidden"
                />
              </div>
              {errors.image && <p className="text-red-500 text-sm mt-1">{errors.image}</p>}

              {(imageURL || image) && (
                <div className="mt-2 flex space-x-2">
                  <button
                    className="bg-blue-500 text-white px-3 py-1 rounded"
                    onClick={() => window.open(imageURL, '_blank')}
                  >
                    View Image
                  </button>
                  <button
                    onClick={handleRemoveImage}
                    className="bg-red-500 text-white px-3 py-1 rounded"
                  >
                    Remove Image
                  </button>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </div>
              )}
            </div>
            <div>
              <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2">Number of Lessons</label>
              <input
                type="number"
                value={numberOfLessons}
                onChange={(e) => {
                  setNumberOfLessons(e.target.value);
                  setErrors((prev) => ({ ...prev, numberOfLessons: '' }));
                }}
                className={`w-full border outline-none ${errors.numberOfLessons ? 'border-red-500' : 'border-gray-300'} rounded-lg px-3 py-2 md:py-3`}
              />
              {errors.numberOfLessons && <p className="text-red-500 text-sm mt-1">{errors.numberOfLessons}</p>}
            </div>
          </div>
          <div className="mb-6 md:mb-7">
            <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-1">Course Link</label>
            <input
              type="text"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                setErrors((prev) => ({ ...prev, link: '' }));
              }}
              className={`w-full md:w-[80%] border outline-none ${errors.link ? 'border-red-500' : 'border-gray-300'} rounded-lg px-3 py-2 md:py-3`}
            />
            {errors.link && <p className="text-red-500 text-sm mt-1">{errors.link}</p>}
          </div>
          <div className="flex space-x-4">
            <button
              className="bg-[#3EBA59] text-white rounded-md px-4 md:px-[19.03px] py-3 md:py-[16.31px] text-sm md:text-[19.03px] leading-[1.2] transition-transform duration-200 ease-in-out transform hover:scale-90 hover:bg-green-500 hover:shadow-lg hover:font-semibold"
              onClick={handleAddCourse}
            >
              {isEditing ? 'Update Course' : 'Add Course'}
            </button>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </>
      ) : (
        <div>
          <h2 className="text-2xl mb-4">Please log in to add a course.</h2>
        </div>
      )}
    </div>
  );
};

export default AddCourses;