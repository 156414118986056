import React, { useEffect } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import Globe from '../Assets/Globe.svg';
import Reminder from '../Assets/Reminder.svg';
import Avtar from '../Assets/Avtar.svg';
import { AiOutlineUnorderedList } from "react-icons/ai";
import { useStateContext } from '../Contexts/ContextProvider';

const Navbar = ({ handleLogout }) => {
  const { currentColor, activeMenu, setActiveMenu, setScreenSize, screenSize } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setActiveMenu(screenSize > 900);
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="flex flex-col h-14">
      <div className={`flex justify-between p-3 items-center bg-[#0D0148] shadow-md fixed top-0 right-0 ${activeMenu ? 'w-[82.79%]' : 'w-full'} z-10`}>
        <div className="relative hidden md:block">
          <button
            className="absolute -left-5 top-2 -translate-y-1/2 rounded-full border border-white p-0.5 ml-2 text-white hover:bg-[#0D0148]/50"
            onClick={handleActiveMenu}
          >
            {activeMenu ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M15 18l-6-6 6-6" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M9 18l6-6-6-6" />
              </svg>
            )}
          </button>
        </div>
        <div className="hidden md:flex items-center gap-4 h-7">
        </div>
        <button
          className="md:hidden p-2 text-white hover:bg-[#0D0148]/50"
          onClick={handleActiveMenu}
        >
          <AiOutlineUnorderedList size={20} />
        </button>
      </div>
    </div>
  );
};

export default Navbar;

