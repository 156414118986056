// import React, { useState, useRef, useEffect } from 'react';
// import axios from 'axios';
// import { storage } from './firebaseConfig';
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import { Bounce, toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const auth = getAuth();

// const AddNotification = ({ selectedItem, fetchAnalysisData, closeModal }) => {
//   const [user, setUser] = useState(null);
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [images, setImages] = useState([]);
//   const [imageURLs, setImageURLs] = useState([]);
//   const [errors, setErrors] = useState({});
//   const fileInputRef = useRef(null);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser ? currentUser : null);
//     });
//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     if (selectedItem) {
//       setDescription(selectedItem.description);
//       setImageURLs(selectedItem.images || []);
//     } else {
//       setDescription('');
//       setImageURLs([]);
//     }
//   }, [selectedItem]);

//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files);
//     setImages(files);

//     const previewURLs = files.map((file) => URL.createObjectURL(file));
//     setImageURLs(previewURLs);
//   };

//   const handleImageClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleRemoveImage = (index) => {
//     setImages((prev) => prev.filter((_, i) => i !== index));
//     setImageURLs((prev) => prev.filter((_, i) => i !== index));
//   };

//   const handleAddOrUpdateAnalysis = async (event) => {
//     event.preventDefault();

//     const newErrors = {};
//     if (!images.length && !selectedItem) newErrors.image = 'At least one image is required';
//     if (!description) newErrors.description = 'Description is required';

//     if (Object.keys(newErrors).length > 0) {
//       setErrors(newErrors);
//       toastError("Please fill in all required fields.");
//       return;
//     }

//     if (!user) {
//       toastError("Please log in to upload images.");
//       return;
//     }

//     try {
//       let uploadedImageURLs = imageURLs;

//       if (images.length) {
//         uploadedImageURLs = await Promise.all(
//           images.map(async (image) => {
//             const imageRef = ref(storage, `notification-images/${image.name}`);
//             await uploadBytes(imageRef, image);
//             return getDownloadURL(imageRef);
//           })
//         );
//       }

//       const data = {
//         adminId: '67290e562faba7bce90089d9',
//         message: description,
//         image: imageURLs,
//       };

//       const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/notifications/send`;
//       const method = "post";

//       const response = await axios({
//         method,
//         url: apiUrl,
//         data,
//       });

//       toastSuccess(selectedItem ? "Analysis updated successfully!" : "Notification added successfully!");

//       setDescription('');
//       setImages([]);
//       setImageURLs([]);
//       setErrors({});
//       fetchAnalysisData();
//       closeModal();
//     } catch (error) {
//       console.error('Error submitting analysis:', error);
//       toastError('Error adding notifications. Please try again.');
//     }
//   };

//   const toastSuccess = (text) => toast.success(text, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "light",
//     transition: Bounce,
//   });

//   const toastError = (text) => toast.error(text, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "light",
//     transition: Bounce,
//   });

//   return (
//     <div className="p-4 md:p-6 mt-20 md:mt-0">
//       {user ? (
//         <div>
//           <h1 className="text-2xl md:text-[43.49px] leading-[1.2] font-bold mb-4 md:mb-7 text-[#4F4F4F]">
//             Add Notifications
//           </h1>
//           <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
//             <div>
//               <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2">Images</label>
//               <div className="flex items-center border border-gray-300 rounded-lg  lg:w-[60%]">
//                 <input
//                   type="text"
//                   readOnly
//                   value={images.length ? `${images.length} images selected` : ''}
//                   className={`flex-grow md:p-3 p-0 outline-none text-sm md:text-base md:ml-2 ml-1 ${errors.image ? 'border-red-500' : ''}`}
//                   placeholder="Choose images"
//                 />
//                 <div className="border-l border-gray-300 p-2 cursor-pointer" onClick={handleImageClick}>
//                   <img src="Plus.svg" alt="Upload" className="w-7 h-7 md:w-6 md:h-6" />
//                 </div>
//                 <input
//                   type="file"
//                   ref={fileInputRef}
//                   onChange={handleFileChange}
//                   className="hidden"
//                   multiple
//                 />
//               </div>
//               {errors.image && <p className="text-red-500 text-sm mt-1">{errors.image}</p>}
//               <div className="flex flex-col gap-2 mt-2">
//                 {imageURLs.map((url, index) => (
//                   <div key={index} className="flex items-center space-x-2">
//                     <button
//                       className="bg-blue-500 text-white px-3 py-1 rounded"
//                       onClick={() => window.open(url, '_blank')}
//                     >
//                       View Image
//                     </button>
//                     <button
//                       className="bg-red-500 text-white px-3 py-1 rounded"
//                       onClick={() => handleRemoveImage(index)}
//                     >
//                       Remove Image
//                     </button>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//           <div className="mb-6 md:mb-7">
//             <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-1">Description</label>
//             <textarea
//               required
//               value={description}
//               onChange={(e) => {
//                 setDescription(e.target.value);
//                 setErrors((prev) => ({ ...prev, description: '' }));
//               }}
//               className={`w-full border outline-none ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg px-3 py-2 md:py-3`}
//               rows="3"
//             ></textarea>
//             {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
//           </div>
//           <div className="flex space-x-4">
//             <button
//               className="bg-[#3EBA59] text-white rounded-md px-4 md:px-[19.03px] py-3 md:py-[16.31px] text-sm md:text-[19.03px] leading-[1.2] transition-transform duration-200 ease-in-out transform hover:scale-105"
//               onClick={handleAddOrUpdateAnalysis}
//             >
//               Add Notification
//             </button>
//             <button
//               className="bg-gray-500 text-white rounded-md px-4 md:px-[19.03px] py-3 md:py-[16.31px] text-sm md:text-[19.03px] leading-[1.2] transition-transform duration-200 ease-in-out transform hover:scale-105"
//               onClick={closeModal}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       ) : (
//         <p>Please log in to add or edit analysis.</p>
//       )}
//       <ToastContainer />
//     </div>
//   );
// };

// export default AddNotification;
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { storage } from './firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const auth = getAuth();

const AddNotification = ({ fetchAnalysisData, closeModal }) => {
  const [user, setUser] = useState(null);
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null); // For storing the selected image file
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser || null);
    });
    return () => unsubscribe();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  const handleAddNotification = async (event) => {
    event.preventDefault();

    const newErrors = {};
    if (!image) newErrors.image = 'An image is required';
    if (!description) newErrors.description = 'Description is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error('Please fill in all required fields.');
      return;
    }

    if (!user) {
      toast.error('Please log in to upload images.');
      return;
    }

    try {
      // Upload the image to Firebase
      const imageRef = ref(storage, `notification-images/${Date.now()}-${image.name}`);
      await uploadBytes(imageRef, image);
      const uploadedImageURL = await getDownloadURL(imageRef);

      // Prepare the API request
      const data = {
        adminId: '67290e562faba7bce90089d9', // Hardcoded adminId
        message: description,
        image: uploadedImageURL, // Send the single image URL directly
      };

      const apiUrl = `https://webai-forex-backend-ashy.vercel.app/api/notifications/send`;

      await axios.post(apiUrl, data);

      toast.success('Notification added successfully!');

      // Reset state and refresh data
      setDescription('');
      setImage(null);
      setErrors({});
      fetchAnalysisData();
      closeModal();
    } catch (error) {
      console.error('Error submitting notification:', error);
      toast.error('Error adding notification. Please try again.');
    }
  };

  return (
    <div className="p-4 md:p-6 mt-20 md:mt-0">
      {user ? (
        <div>
          <h1 className="text-2xl md:text-[43.49px] leading-[1.2] font-bold mb-4 md:mb-7 text-[#4F4F4F]">
            Add Notification
          </h1>
          <div className="mb-4">
            <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2">Image</label>
            <div className="flex items-center border border-gray-300 rounded-lg">
              <input
                type="text"
                readOnly
                value={image ? image.name : ''}
                className={`flex-grow p-2 outline-none text-sm ${errors.image ? 'border-red-500' : ''}`}
                placeholder="Choose an image"
              />
              <div className="border-l border-gray-300 p-2 cursor-pointer" onClick={handleImageClick}>
                <img src="Plus.svg" alt="Upload" className="w-6 h-6" />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                accept="image/*"
              />
            </div>
            {errors.image && <p className="text-red-500 text-sm mt-1">{errors.image}</p>}
            {image && (
              <div className="mt-3 flex items-center space-x-4">
                <p className="text-sm text-gray-700">{image.name}</p>
                <button
                  className="bg-red-500 text-white px-3 py-1 rounded"
                  onClick={handleRemoveImage}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
          <div className="mb-6 md:mb-7">
            <label className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-1">Description</label>
            <textarea
              required
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setErrors((prev) => ({ ...prev, description: '' }));
              }}
              className={`w-full border outline-none ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg px-3 py-2`}
              rows="3"
            ></textarea>
            {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
          </div>
          <div className="flex space-x-4">
            <button
              className="bg-[#3EBA59] text-white rounded-md px-4 py-3 transition-transform duration-200 ease-in-out transform hover:scale-105"
              onClick={handleAddNotification}
            >
              Add Notification
            </button>
            <button
              className="bg-gray-500 text-white rounded-md px-4 py-3 transition-transform duration-200 ease-in-out transform hover:scale-105"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <p>Please log in to add or edit notifications.</p>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddNotification;
