import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./Contexts/ContextProvider";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <ContextProvider>
    <div className="background-container">
      <img src="bg.svg" alt="background" />
    </div>
    <App />
    <ToastContainer />
  </ContextProvider>,
  document.getElementById("root")
);
