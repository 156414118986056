import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from 'react-responsive-modal';
import AddCourses from "./AddCourses";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Bounce, toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Courses = () => {
  const [selectedTab, setSelectedTab] = useState("all");
  const [courses, setCourses] = useState([]);
  const [isAddCourseModalOpen, setIsAddCourseModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);


  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/courses`);
      setCourses(response.data);
    } catch (error) {
      toastError('Error fetching courses:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    const filtered = courses.filter(course =>
      course.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [search, courses]);


  const handleAddCourseClick = () => {
    setIsAddCourseModalOpen(true);
    setIsEditing(false);
    setCurrentCourse(null);
  };

  const handleEditCourseClick = (course) => {
    setIsAddCourseModalOpen(true);
    setIsEditing(true);
    setCurrentCourse(course);
  };

  const closeAddCourseModal = () => {
    setIsAddCourseModalOpen(false);
    setCurrentCourse(null);
  };

  const handleDeleteCourse = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/courses/${id}`);
      setCourses(courses.filter(course => course._id !== id));
      toastSuccess("Course deleted successfully.");
    } catch (error) {
      toastError("Error deleting course:", error);
    }
  };

  const toastSuccess = (text) => toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  const toastError = (text) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  return (
    <>
    <div className='min-h-[91vh] relative' style={{ backgroundImage: 'url(bg.svg)'}}>
    <div className="w-full p-5 mt-16 sm:mt-0">
      <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>COURSES</h1>

      {/* <div className="flex justify-between w-full bg-red-400"> */}
      <div className="flex justify-between flex-col md:flex-row gap-3 md:gap-0 w-44 md:w-auto">
        <div>
          <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
            <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
                <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
            />
          </div>
        </div>
        <button
          onClick={handleAddCourseClick}
          className=" border border-[#B4CAF4] rounded-[15px] md:rounded-[6px] px-4 py-2 flex items-center hover:bg-[#0760FF] hover:bg-opacity-25 cursor-pointer mb-5"
        >
          <span className='text-[#0760FF] hover:text-blue-800 text-[15px] leading-[17.63px] p-2 font-medium hover:font-semibold cursor-pointer'>Add Courses</span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3103 2.70044C12.6274 2.0173 11.6628 1.91016 10.5245 1.91016H5.46196C4.33711 1.91016 3.37282 2.0173 2.68968 2.70016C2.00654 3.38301 1.90625 4.34073 1.90625 5.4593V10.5216C1.90625 11.6667 2.00682 12.6176 2.68968 13.3007C3.37254 13.9839 4.33711 14.0907 5.48225 14.0907H10.5245C11.6628 14.0907 12.6274 13.9836 13.3103 13.3007C13.9931 12.6179 14.0937 11.6667 14.0937 10.5216V5.4793C14.0937 4.33416 13.9934 3.37673 13.3103 2.70044ZM7.99996 11.9276C7.85975 11.9274 7.72533 11.8716 7.62616 11.7725C7.52698 11.6733 7.47112 11.5389 7.47082 11.3987V8.52616H4.60511C4.31025 8.52616 4.07596 8.28501 4.07596 8.00387C4.07596 7.72273 4.31025 7.46816 4.60482 7.46816H7.47082V4.60187C7.47082 4.30073 7.70511 4.06616 7.99996 4.06616C8.30139 4.06616 8.52911 4.30044 8.52911 4.60187V7.46816H11.4017C11.6965 7.46816 11.9308 7.72244 11.9308 8.00387C11.9308 8.28501 11.6965 8.52616 11.4017 8.52616H8.52911V11.399C8.52953 11.4686 8.51612 11.5376 8.48967 11.602C8.46323 11.6663 8.42426 11.7248 8.37504 11.774C8.32582 11.8232 8.26732 11.8621 8.20293 11.8885C8.13854 11.915 8.06956 11.928 7.99996 11.9276Z" fill="#04CF31" />
          </svg>
        </button>
      </div>
      {/* <button
          onClick={handleAddCourseClick}
          className="bg-blue-500 text-white font-semibold px-4 py-2 rounded-md transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-blue-600 ml-6 mt-4 mb-5"
        >
          Add Course
        </button> */}
      {/* </div> */}

      <div className="flex flex-col md:flex-row justify-center items-center md:items-center md:justify-between md:flex-wrap gap-10 px-0 mb-16" style={{ maxWidth: '100%' }}>
        {filteredCourses.map((course) => (
          <div key={course._id} className="relative md:w-[23rem] bg-[#FAFAFA] border px-2 pt-2 pb-6 rounded-lg self-stretch">
            <img
              src={course.image}
              alt={course.title}
              className="rounded-md w-full h-44 object-cover"
            />
            <h2 className="font-semibold text-sm py-4 truncate">
              {course.title}
            </h2>
            <div className="flex items-center justify-between gap-1 pb-8 pt-4">
              <div className="flex items-center gap-2">
                <img src="Lesson.svg" alt="LessonImg" />
                <span className="text-xs font-medium">Lesson : {course.numberOfLessons}</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <a href={course.link} target="_blank" rel="noopener noreferrer">
                <button className="text-sm font-medium bg-[#3EBA59] text-white px-4 py-3 rounded-lg transition-transform transform duration-200 ease-in-out hover:scale-105 hover:shadow-lg hover:bg-[#2E9C47] hover:font-semibold">
                  Start Course {">"}
                </button>
              </a>
            </div>

            <div className="absolute bottom-10 right-2 flex gap-2">
              <FaEdit
                className="cursor-pointer text-blue-500 hover:text-blue-700 w-7"
                onClick={() => handleEditCourseClick(course)}
              />
              <button>
                <FaTrash
                  className="cursor-pointer text-red-500 hover:text-red-700 w-7"
                  onClick={() => handleDeleteCourse(course._id)}
                />
              </button>

              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </div>
          </div>
        ))}
      </div>


      <Modal
        open={isAddCourseModalOpen}
        onClose={closeAddCourseModal}
        center
        styles={{
          modal: {
            width: '80%',
            maxWidth: '80%',
          },
        }}
      >
        <AddCourses
          closeModal={closeAddCourseModal}
          isEditing={isEditing}
          courseData={currentCourse}
          refreshCourses={fetchCourses}
        />
      </Modal>
    </div>
    </div>
    </>
  );
};

export default Courses;
