// import React from 'react'
// import { Outlet } from 'react-router-dom'
// import MemberChat from './MembersChat'
// import Participants from './Participants'

// const Signals = () => {
//     return (
//         <div className='grid lg:grid-cols-[300px,1fr] h-screen max-h-screen'>
//             {/* <section className={`bg-white ${!basePath && "hidden"} lg:block`}> */}
//             <section className={"bg-white lg:block"}>
//                 <Participants />
//             </section>

//             {/**message component**/}
//             {/* <section className={`${basePath && "hidden"}`} > */}
//             <section className={"hidden"} >
//                 <Outlet />
//             </section>
//             <MemberChat />



//             {/* <div className={`justify-center items-center flex-col gap-2 hidden ${!basePath ? "hidden" : "lg:flex"}`}> */}
//             {/* <div>
//           <img
//             src={logo}
//             width={250}
//             alt='logo'
//           />
//         </div>
//         <p className='text-lg mt-2 text-slate-500'>Select user to send message</p> */}
//             {/* </div> */}
//         </div>
//     )
// }
// export default Signals
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Participants from './Participants';
import MemberChat from './MembersChat';
import axios from 'axios';

const Signals = () => {
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [participants, setParticipants] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://webai-forex-backend-ashy.vercel.app/api/chat/group/67290e562faba7bce90089d9")
                const apiMessages = response.data.messages.map(msg => ({
                    type: msg.message && (msg.message.startsWith('https://firebasestorage.googleapis.com') || /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(msg.message)) ? 'image' : 'text',
                    direction: 'outgoing',
                    payload: msg.message || '',
                    position: 'single',
                    sender: msg.senderUsername,
                    sentTime: new Date(msg.timestamp).toLocaleTimeString(),
                }))
                // setMessages(apiMessages)
                setParticipants(response.data.participants)
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        }
        fetchData()
    }, [])

    return (

        <MemberChat participant={selectedParticipant} />

        // <div className='grid lg:grid-cols-[300px,1fr] h-screen max-h-screen'>
        //     <section className={`bg-white ${!selectedParticipant ? '' : 'hidden'} lg:block`}>
        //         <Participants onSelect={setSelectedParticipant} userName={participants} />
        //     </section>

        //     {/* Conditionally render chat based on participant selection */}
        //     <MemberChat participant={selectedParticipant} />

        //     {/* Outlet for nested routes */}
        //     <section className={selectedParticipant ? "hidden" : ""}>
        //         <Outlet />
        //     </section>
        // </div>
    );
};

export default Signals;
