import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons
import Background from '../Assets/Background.svg';
import Tracker from '../Assets/Tracker.svg';
import Help from '../Assets/Help.svg';
import LoginBg from '../Assets/LoginBg.svg';
import Email from '../Assets/Email.svg';
import axios from 'axios';
import { useStateContext } from '../Contexts/ContextProvider';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const auth = getAuth();

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const endPoint = 'https://webai-forex-backend-ashy.vercel.app';
    const { setIsAuthenticated } = useStateContext();

    const backgroundStyle = {
        height: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email && password) {
            try {
                await signInWithEmailAndPassword(auth, email, password);
                const response = await axios.post(`${endPoint}/api/admin/login`, {
                    email: email,
                    password: password
                });
                if (response.status === 200) {
                    const data = response.data;
                    console.log('Login data: ', data);
                    localStorage.setItem('user', JSON.stringify(data));
                    setIsAuthenticated(true);
                    toast.success('Login Successful!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    navigate('/membersChat');
                } else {
                    toastError('Login failed, please check your credentials');
                }
            } catch (error) {
                console.error(error);
                toast.error('Login Failed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } else {
            toast.error('Please fill out both the fields!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    const toastError = (text) => toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    return (
        <div style={backgroundStyle}>
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="relative w-[90%] max-w-[900px] h-[90%] max-h-[555px] bg-[#FAFBFC] rounded-lg px-8 py-8 shadow-2xl" onClick={(e) => e.stopPropagation()}>
                   
                    <div className="md:hidden mb-2 flex justify-center">
                        <img src="mainlogos.svg" alt="" className='' />
                    </div>

                    <div className="absolute bottom-1 left-0 -mr-[1px] -mb-[4px]">
                        <img src={LoginBg} alt="Background" className="hidden md:block" />
                    </div>

                    <div className="w-full md:w-[384px] h-[400.6px] relative px-5 rounded-lg bg-white shadow-lg mx-auto py-10">
                        <h2 className="text-[#292D34] font-custom text-[20px] md:text-[25.6px] font-bold leading-[36.8px] text-center mb-1 sm:mb-4">
                            Welcome back Admin!
                        </h2>

                        <div className="max-w-md text-start mx-auto p-2">
                            <div className="mb-6">
                                <div className="relative mb-6">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                        <img src={Email} alt="Trader ID" className="w-6 h-6" />
                                    </span>
                                    <input
                                        className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter email"
                                    />
                                </div>
                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                        <img src={Tracker} alt="Trader ID" className="w-6 h-6" />
                                    </span>
                                    <input
                                        className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your password"
                                    />
                                    <span
                                        className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button className="w-full p-4 text-[14px] bg-[#3EBA59] text-white rounded-lg hover:bg-green-500 mb-4" onClick={handleSubmit}>
                            Log In
                        </button>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />

                        <button className="absolute -bottom-[67px] md:-bottom-0 -right-8 md:-right-16">
                            <img src={Help} alt="Help" />
                        </button>
                        <div className="-bottom-20 left-[23%] absolute hidden md:flex">
                        <img src="mainlogo.svg" alt="" className='' />
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;





// import React, { useState } from 'react';
// import Background from '../Assets/Background.svg';
// import Tracker from '../Assets/Tracker.svg';
// import Help from '../Assets/Help.svg';
// import Email from '../Assets/Email.svg';
// import LoginBg from '../Assets/LoginBg.svg';
// import { useNavigate } from 'react-router-dom';
// import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// const auth = getAuth();

// const Login = ({ onLogin }) => {
//     const [email, setEmail] = useState('');
//     const [traderId, setTraderId] = useState('');
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();

//     const backgroundStyle = {
//         height: '100vh',
//         backgroundImage: `url(${Background})`,
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'center',
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null); // Reset error state

//         if (email && traderId) {
//             try {
//                 await signInWithEmailAndPassword(auth, email, traderId);
//                 alert("Login successful!");
//                 onLogin(email, traderId); // Update auth state in app
//                 navigate('/membersChat');
//             } catch (error) {
//                 console.error("Login error:", error);
//                 setError(error.message); // Set error message
//             }
//         } else {
//             alert('Please fill out both fields.');
//         }
//     };

//     return (
//         <div style={backgroundStyle}>
//             <div
//                 className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
//                 onClick={() => navigate('/')}
//             >
//                 <div
//                     className="relative w-[90%] max-w-[900px] h-[90%] max-h-[555px] bg-[#FAFBFC] rounded-lg p-8 shadow-2xl"
//                     onClick={(e) => e.stopPropagation()}
//                 >
//                     <div className='flex mt-2 mb-[10px] md:mb-0 md:mt-4 justify-between'>
//                         <h1 className="text-[24px] md:text-[28px] font-custom leading-[28px] font-bold text-[#190041]">
//                             Forexxy - Tradie
//                         </h1>
//                     </div>

//                     <div className='absolute bottom-0 left-0 z-0'>
//                         <img src={LoginBg} alt="Background" className="w-full h-auto" />
//                     </div>

//                     <div className='w-full md:w-[384px] h-[400.6px] py-2 relative px-5 rounded-lg bg-white shadow-lg mx-auto'>
//                         <h2 className="text-[#292D34] font-custom text-[20px] md:text-[25.6px] font-bold leading-[36.8px] text-center mb-4">
//                             SIGN IN
//                         </h2>

//                         <form onSubmit={handleSubmit}>
//                             <div className="mb-6">
//                                 <div className="relative">
//                                     <span className="absolute inset-y-0 left-0 flex items-center pl-2">
//                                         <img src={Email} alt="Email" className='w-6 h-6' />
//                                     </span>
//                                     <input
//                                         className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                         id="email"
//                                         type="email"
//                                         value={email}
//                                         onChange={(e) => setEmail(e.target.value)}
//                                         placeholder="Enter your email"
//                                         required
//                                     />
//                                 </div>
//                             </div>

//                             <div className="mb-6">
//                                 <div className="relative">
//                                     <span className="absolute inset-y-0 left-0 flex items-center pl-2">
//                                         <img src={Tracker} alt="Trader ID" className='w-6 h-6' />
//                                     </span>
//                                     <input
//                                         className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                         id="traderId"
//                                         type="password"
//                                         value={traderId}
//                                         onChange={(e) => setTraderId(e.target.value)}
//                                         placeholder="Enter password"
//                                         required
//                                     />
//                                 </div>
//                             </div>

//                             {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

//                             <button
//                                 type="submit"
//                                 className="w-full p-3 bg-[#D91222] text-white rounded-lg hover:bg-red-600 mb-4"
//                             >
//                                 Login
//                             </button>
//                         </form>

//                         <button className='absolute bottom-0 -right-0 md:-right-16'>
//                             <img src={Help} alt="Help" />
//                         </button>
//                     </div>

//                     <div className="font-custom absolute bottom-7 left-[2%] md:left-[21%] lg:left-[27%] text-[10px] font-normal md:text-white text-gray-600 text-opacity-80 leading-[14px] text-center px-3 sm:px-0">
//                         This site is protected by reCAPTCHA and the Google
//                         <span className="font-custom text-gray-700 md:text-white text-[9.84px] font-medium leading-[14px] underline"> Privacy Policy </span>
//                         and
//                         <span className="font-custom text-gray-700 md:text-white text-[9.84px] underline font-medium leading-[14px]"> Terms of Service </span>
//                         apply.
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Login;