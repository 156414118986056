import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAkHuY5O3I7l8eQA_InDBGyBMefeWvHDzQ",
  authDomain: "nukkad-foods.firebaseapp.com",
  projectId: "nukkad-foods",
  storageBucket: "nukkad-foods.appspot.com",
  messagingSenderId: "170641463923",
  appId: "1:170641463923:web:02fa4a63405880dddd2806",
  measurementId: "G-0GFLZ78DW8"
}

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);

export { storage, auth };