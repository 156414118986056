// import React, { useState} from 'react';
// import DataTable from 'react-data-table-component';
// import './Subadmin.css';

// const Table1 = () => {
//     const [search, setSearch] = useState('');

//     const columns = [
//         {
//             name: 'NAME',
//             selector: row => row.name,
//             sortable: true,
//         },
//         {
//           name: 'SUBADMINID',
//           selector: row => row.subadminid,
//           sortable: true,
//           grow: 0, 
//           width: '180px', 
//       },
//       {
//           name: 'EMAIL',
//           selector: row => row.email,
//           sortable: true,
//           grow: 0, 
//           width: '300px', 
//       },
//         {
//             name: 'PHONE NO.',
//             selector: row => row.phone,
//             sortable: true,
//             grow: 0,
//           width: '200px',
//         },
//         {
//             name: 'ACTION',
//             cell: row => (
//                 <div className="flex space-x-4 justify-center py-3">
//                     <button className="px-4 py-2 rounded-lg bg-red-500 text-white font-semibold hover:bg-red-600 transition duration-200">
//                         Block
//                     </button>
//                     <button className="px-4 py-2 rounded-lg bg-green-500 text-white font-semibold hover:bg-green-600 transition duration-200">
//                         Unblock
//                     </button>
//                 </div>
//             ),
//         },

//     ];

//     const data = [
//       {
//           "_id": { "$oid": "6726aec7630aba2ce8f165b4" },
//           "name": "Ayush prashant",
//           "subadminid": "Ayush@123",
//           "email": "ayushprashant12345@gmail.com",
//           "phone": "9905382798",
//           "password": "securePassword123",
//           "__v": 0
//       },
//   ]


//     const filteredData = data.filter(item =>
//         item.name.toLowerCase().includes(search.toLowerCase())
//     );

//     const customStyles = {
//         header: {
//             style: {
//                 minHeight: '56px',
//                 backgroundColor: '#f1f3f5',
//                 color: '#343a40',
//                 fontSize: '18px',
//                 fontWeight: 'bold',
//             },
//         },
//         headRow: {
//             style: {
//                 backgroundColor: '#f1f3f5',
//                 borderBottomWidth: '2px',
//                 borderBottomColor: '#dee2e6',
//                 borderBottomStyle: 'solid',
//             },
//         },
//         headCells: {
//             style: {
//                 color: '#343a40',
//                 fontWeight: 'bold',
//                 fontSize: '18px',
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//             },
//         },
//         rows: {
//             style: {
//                 minHeight: '48px',
//                 '&:not(:last-of-type)': {
//                     borderBottomWidth: '1px',
//                     borderBottomColor: '#dee2e6',
//                     borderBottomStyle: 'solid',
//                 },
//             },
//         },
//         cells: {
//             style: {
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//                 fontSize: '16px',
//             },
//         },
//         pagination: {
//             style: {
//                 borderTopWidth: '1px',
//                 borderTopColor: '#dee2e6',
//                 borderTopStyle: 'solid',
//                 backgroundColor: '#f1f3f5',
//                 minHeight: 'auto'
//             },
//         },
//     };

//     const laptopPaginationStyles = `
//     @media (min-width: 1024px) {
//         .rdt_Pagination {
//             min-height: 56px;
//         }
//     }
// `;

//     return (
//         <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', }}>
//             <style>{laptopPaginationStyles}</style>
//             <div className="flex justify-end mb-5 md:mb-3 mt-16 md:mt-0">
//                 <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//                     <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//                             <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                             <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                         </svg>
//                     </div>
//                     <input
//                         type="text"
//                         placeholder="Search..."
//                         value={search}
//                         onChange={e => setSearch(e.target.value)}
//                         className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//                     />
//                 </div>
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredData}
//                 pagination
//                 highlightOnHover
//                 pointerOnHover
//                 dense
//                 customStyles={customStyles}
//             />
//         </div>
//     );
// };

// export default Table1;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import DataTable from 'react-data-table-component';
// import './Subadmin.css';

// const Table1 = () => {
//     const [search, setSearch] = useState('');
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         const fetchSuspendedUsers = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/suspended-users`);
//                 const formattedData = response.data.map(user => ({
//                     _id: user._id.$oid,
//                     userId: user.userId,
//                     name: user.username,
//                     subadminid: user.traderId,
//                     email: user.email,
//                     phone: user.reason || 'N/A',
//                 }));
//                 setData(formattedData);
//                 console.log(response.data)
//             } catch (error) {
//                 console.error("Error fetching suspended users:", error);
//             }
//         };
//         fetchSuspendedUsers();
//     }, []);


//      const handleBlockUser = async (userId) => {
//         try {
//             await axios.put(`${process.env.REACT_APP_BASE_URL}/api/auth/block-user/${userId}`);
//             alert("User blocked successfully");
//         } catch (error) {
//             console.error("Error blocking user:", error);
//         }
//     };

//     const handleUnblockUser = async (userId) => {
//         try {
//             await axios.put(`${process.env.REACT_APP_BASE_URL}/api/auth/unblock-user/${userId}`);
//             alert("User unblocked successfully");
//         } catch (error) {
//             console.error("Error unblocking user:", error);
//         }
//     };

//     const columns = [
//         {
//             name: 'NAME',
//             selector: row => row.name,
//             sortable: true,
//             width: '150px'
//         },
//         {
//             name: 'TRADERID',
//             selector: row => row.subadminid,
//             sortable: true,
//             width: '180px'
//         },
//         {
//             name: 'EMAIL',
//             selector: row => row.email,
//             sortable: true,
//             width: '300px',
//         },
//         {
//             name: 'REASON',
//             selector: row => row.phone,
//             sortable: true,
//             width: '250px',
//         },
//         {
//             name: 'ACTION',
//             cell: row => (
//                 <div className="flex space-x-4 justify-center py-2">
//                     <button onClick={() => handleBlockUser(row.userId)} className="px-4 py-2 rounded-lg bg-red-500 text-white font-semibold hover:bg-red-600 transition duration-200 w-28">
//                         Block
//                     </button>
//                     <button  onClick={() => handleUnblockUser(row.userId)} className="px-4 py-2 rounded-lg bg-green-500 text-white font-semibold hover:bg-green-600 transition duration-200 w-28">
//                         Unblock
//                     </button>
//                 </div>
//             ),
//         },
//     ];

//     const filteredData = data.filter(item =>
//         item.name.toLowerCase().includes(search.toLowerCase())
//     );

//     const customStyles = {
//         header: {
//             style: {
//                 minHeight: '56px',
//                 backgroundColor: '#f1f3f5',
//                 color: '#343a40',
//                 fontSize: '18px',
//                 fontWeight: 'bold',
//             },
//         },
//         headRow: {
//             style: {
//                 backgroundColor: '#f1f3f5',
//                 borderBottomWidth: '2px',
//                 borderBottomColor: '#dee2e6',
//                 borderBottomStyle: 'solid',
//             },
//         },
//         headCells: {
//             style: {
//                 color: '#343a40',
//                 fontWeight: 'bold',
//                 fontSize: '18px',
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//             },
//         },
//         rows: {
//             style: {
//                 minHeight: '48px',
//                 '&:not(:last-of-type)': {
//                     borderBottomWidth: '1px',
//                     borderBottomColor: '#dee2e6',
//                     borderBottomStyle: 'solid',
//                 },
//             },
//         },
//         cells: {
//             style: {
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//                 fontSize: '16px',
//             },
//         },
//         pagination: {
//             style: {
//                 borderTopWidth: '1px',
//                 borderTopColor: '#dee2e6',
//                 borderTopStyle: 'solid',
//                 backgroundColor: '#f1f3f5',
//                 minHeight: 'auto'
//             },
//         },
//     };

//     const laptopPaginationStyles = `
//     @media (min-width: 1024px) {
//         .rdt_Pagination {
//             min-height: 56px;
//         }
//     }
// `;

//     return (
//         <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto' }}>
//             <style>{laptopPaginationStyles}</style>
//             <div className="flex justify-end mb-5 md:mb-3 mt-16 md:mt-0">
//                 <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//                     <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//                             <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                             <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                         </svg>
//                     </div>
//                     <input
//                         type="text"
//                         placeholder="Search..."
//                         value={search}
//                         onChange={e => setSearch(e.target.value)}
//                         className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//                     />
//                 </div>
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredData}
//                 pagination
//                 highlightOnHover
//                 pointerOnHover
//                 dense
//                 customStyles={customStyles}
//             />
//         </div>
//     );
// };

// export default Table1;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FiUserX, FiUnlock, FiEdit, FiTrash2 } from 'react-icons/fi';
import './Subadmin.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Table1 = () => {
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [editingUser, setEditingUser] = useState(null); // Track user being edited
    const [editForm, setEditForm] = useState({
        traderId: '',
        email: '',
        username: '',
        reason: ''
    });

    // useEffect(() => {
        const fetchSuspendedUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/suspended-users`);
                const formattedData = response.data.map(user => ({
                    _id: user._id,
                    userId: user.userId,
                    name: user.username,
                    subadminid: user.traderId,
                    email: user.email,
                    reason: user.reason || 'N/A',
                }));
                setData(formattedData);
            } catch (error) {
                console.error("Error fetching suspended users:", error);
            }
        };
        fetchSuspendedUsers();
    // }, []);
                    


    const handleBlockUser = async (userId) => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/api/auth/block-user/${userId}`);
            toast.success("User blocked successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            toast.error("Failed to block user. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };


    const handleUnblockUser = async (userId) => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/api/auth/unblock-user/${userId}`);
            toast.success("User unblocked successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            toast.error("Failed to Unblock user. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };



    const handleDelete = async (userId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (confirmDelete) {
            try {
                // Make a DELETE request to the endpoint to delete the user
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/auth/delete-user/${userId}`);

                // Update the state to remove the deleted user from the data
                setData(data.filter(item => item.userId !== userId));

                toast.success("User Delete successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } catch (error) {
                console.error("Error deleting user:", error);
            }
        }
    };


    // const handleEdit = (_id) => {
    //     alert(`Edit functionality for user ID: ${_id}`);
    // };

    const handleEdit = (user) => {
        setEditingUser(user);
        setEditForm({
            traderId: user.subadminid,
            email: user.email,
            username: user.name,
            reason: user.reason
        });
    };

    

    const handleEditSubmit = async () => {
        try {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/auth/edit-suspended-user/${editingUser._id}`,
                editForm
            );
            toast.success("User updated successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            // Update the data in the table without reloading
            setData(data.map(item => 
                item._id === editingUser._id ? { ...item, ...editForm } : item
            ));
            setEditingUser(null); // Close the edit form
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    
    const closeModal = () => setEditingUser(null);

    const columns = [
        {
            name: 'NAME',
            selector: row => row.name,
            sortable: true,
            width: '180px'
        },
        {
            name: 'TRADERID',
            selector: row => row.subadminid,
            sortable: true,
            width: '180px'
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
            sortable: true,
            width: '300px',
        },
        {
            name: 'REASON',
            selector: row => row.reason,
            sortable: true,
            width: '250px',
        },
        {
            name: 'ACTION',
            cell: row => (
                <div className="flex space-x-4 justify-center py-4">
                    <button onClick={() => handleBlockUser(row.userId)} title="Block" className="text-red-500 hover:text-red-600 transition duration-200">
                        <FiUserX />
                    </button>
                    <button onClick={() => handleUnblockUser(row.userId)} title="Unblock" className="text-green-500 hover:text-green-600 transition duration-200">
                        <FiUnlock />
                    </button>
                     <button onClick={() => handleEdit(row)} title="Edit" className="text-blue-500 hover:text-blue-600 transition duration-200">
                        <FiEdit />
                     </button>
                    <button onClick={() => handleDelete(row.userId)} title="Delete" className="text-red-500 hover:text-red-600 transition duration-200">
                        <FiTrash2 />
                    </button>
                </div>
            ),
        },
    ];

    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    const customStyles = {
        header: {
            style: {
                minHeight: '56px',
                backgroundColor: '#f1f3f5',
                color: '#343a40',
                fontSize: '18px',
                fontWeight: 'bold',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#f1f3f5',
                borderBottomWidth: '2px',
                borderBottomColor: '#dee2e6',
                borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                color: '#343a40',
                fontWeight: 'bold',
                fontSize: '18px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        rows: {
            style: {
                minHeight: '48px',
                '&:not(:last-of-type)': {
                    borderBottomWidth: '1px',
                    borderBottomColor: '#dee2e6',
                    borderBottomStyle: 'solid',
                },
            },
        },
        cells: {
            style: {
                paddingLeft: '16px',
                paddingRight: '16px',
                fontSize: '16px',
            },
        },
        pagination: {
            style: {
                borderTopWidth: '1px',
                borderTopColor: '#dee2e6',
                borderTopStyle: 'solid',
                backgroundColor: '#f1f3f5',
                minHeight: 'auto'
            },
        },
    };

    const laptopPaginationStyles = `
         @media (min-width: 1024px) {
             .rdt_Pagination {
                 min-height: 56px;
             }
         }
     `;

    return (
        <>
        <div className='min-h-[91vh] relative pt-1' style={{ backgroundImage: 'url(bg.svg)',}}>
        <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', marginTop: "10px" }}>
            <style>{laptopPaginationStyles}</style>
            <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>MEMBERS</h1>
            <div className="flex justify-end mb-5 md:mb-5 mt-16 md:mt-0">
                <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
                    <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
                            <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
                    />
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                pointerOnHover
                dense
                customStyles={customStyles}
            />
            
        </div>
        </div>
        {editingUser && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={closeModal}>
        <div className="bg-white p-6 rounded-lg w-[400px] shadow-lg flex flex-col gap-4" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-xl font-semibold mb-4">Edit Suspended User</h2>
            
            <label className="text-sm font-medium text-gray-700">
                Trader ID
                <input
                    type="text"
                    placeholder="Trader ID"
                    value={editForm.traderId}
                    onChange={(e) => setEditForm({ ...editForm, traderId: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 text-gray-900"
                />
            </label>

            <label className="text-sm font-medium text-gray-700">
                Email
                <input
                    type="email"
                    placeholder="Email"
                    value={editForm.email}
                    onChange={(e) => setEditForm({ ...editForm, email: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 text-gray-900"
                />
            </label>

            <label className="text-sm font-medium text-gray-700">
                Username
                <input
                    type="text"
                    placeholder="Username"
                    value={editForm.username}
                    onChange={(e) => setEditForm({ ...editForm, username: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 text-gray-900"
                />
            </label>

            <label className="text-sm font-medium text-gray-700">
                Reason
                <input
                    type="text"
                    placeholder="Reason"
                    value={editForm.reason}
                    onChange={(e) => setEditForm({ ...editForm, reason: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 text-gray-900"
                />
            </label>

            <div className="flex gap-4 mt-4">
                <button 
                    onClick={handleEditSubmit} 
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                >
                    Save
                </button>
                <button 
                    onClick={closeModal} 
                    className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
)}

        </>
    );
};

export default Table1;




//     const columns = [
//         {
//             name: 'NAME',
//             selector: row => row.name,
//             sortable: true,
//             width: '180px'
//         },
//         {
//             name: 'TRADERID',
//             selector: row => row.subadminid,
//             sortable: true,
//             width: '180px'
//         },
//         {
//             name: 'EMAIL',
//             selector: row => row.email,
//             sortable: true,
//             width: '300px',
//         },
//         {
//             name: 'REASON',
//             selector: row => row.reason,
//             sortable: true,
//             width: '250px',
//         },
//         {
//             name: 'ACTION',
//             cell: row => (
//                 <div className="flex space-x-4 justify-center py-4">
//                     <button onClick={() => handleBlockUser(row.userId)} title="Block" className="text-red-500 hover:text-red-600 transition duration-200">
//                         <FiUserX />
//                     </button>
//                     <button onClick={() => handleUnblockUser(row.userId)} title="Unblock" className="text-green-500 hover:text-green-600 transition duration-200">
//                         <FiUnlock />
//                     </button>
//                     <button onClick={() => handleEdit(row)} title="Edit" className="text-blue-500 hover:text-blue-600 transition duration-200">
//                         <FiEdit />
//                     </button>
//                     <button onClick={() => handleDelete(row.userId)} title="Delete" className="text-red-500 hover:text-red-600 transition duration-200">
//                         <FiTrash2 />
//                     </button>
//                 </div>
//             ),
//         },
//     ];

//     const filteredData = data.filter(item =>
//         item.name.toLowerCase().includes(search.toLowerCase())
//     );

//     return (
//         <>
//             <div className='min-h-[91vh] relative pt-1'>
//                 <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', marginTop: "10px" }}>
//                     <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>MEMBERS</h1>
//                     <div className="flex justify-end mb-5">
//                         <input
//                             type="text"
//                             placeholder="Search..."
//                             value={search}
//                             onChange={e => setSearch(e.target.value)}
//                             className="input-search"
//                         />
//                     </div>
//                     <DataTable
//                         columns={columns}
//                         data={filteredData}
//                         pagination
//                         highlightOnHover
//                         pointerOnHover
//                         dense
//                     />
//                 </div>
//             </div>

//             {editingUser && (
//                 <div className="modal-overlay" onClick={closeModal}>
//                     <div className="modal-content" onClick={e => e.stopPropagation()}>
//                         <h2>Edit Suspended User</h2>
//                         <input
//                             type="text"
//                             placeholder="Trader ID"
//                             value={editForm.traderId}
//                             onChange={(e) => setEditForm({ ...editForm, traderId: e.target.value })}
//                         />
//                         <input
//                             type="email"
//                             placeholder="Email"
//                             value={editForm.email}
//                             onChange={(e) => setEditForm({ ...editForm, email: e.target.value })}
//                         />
//                         <input
//                             type="text"
//                             placeholder="Username"
//                             value={editForm.username}
//                             onChange={(e) => setEditForm({ ...editForm, username: e.target.value })}
//                         />
//                         <input
//                             type="text"
//                             placeholder="Reason"
//                             value={editForm.reason}
//                             onChange={(e) => setEditForm({ ...editForm, reason: e.target.value })}
//                         />
//                         <button onClick={handleEditSubmit}>Save</button>
//                         <button onClick={closeModal}>Cancel</button>
//                     </div>
//                 </div>
//             )}
            
//             <style jsx>{`
//                 .modal-overlay {
//                     position: fixed;
//                     top: 0;
//                     left: 0;
//                     right: 0;
//                     bottom: 0;
//                     background: rgba(0, 0, 0, 0.5);
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     z-index: 1000;
//                 }
//                 .modal-content {
//                     background: white;
//                     padding: 20px;
//                     border-radius: 8px;
//                     width: 400px;
//                     box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
//                     display: flex;
//                     flex-direction: column;
//                     gap: 10px;
//                 }
//                 .modal-content h2 {
//                     margin-bottom: 10px;
//                 }
//                 .modal-content input {
//                     padding: 8px;
//                     border: 1px solid #ddd;
//                     border-radius: 4px;
//                 }
//                 .modal-content button {
//                     margin-top: 10px;
//                     padding: 8px;
//                     background-color: #007bff;
//                     color: white;
//                     border: none;
//                     border-radius: 4px;
//                     cursor: pointer;
//                 }
//                 .modal-content button:nth-child(2) {
//                     background-color: #6c757d;
//                 }
//             `}</style>
//         </>
//     );
// };

// export default Table1;
