import axios from 'axios';
import DataTable from 'react-data-table-component';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './Subadmin.css';
import { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleLeft } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import backgroundImage from '../Assets/wallapaper.jpeg'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from './firebaseConfig'
import { Dropdown } from 'primereact/dropdown';


const Support = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [username, setUserName] = useState(null); // Tracks the current user for personalized chat
  const [messages, setMessages] = useState([]); // Stores messages for the chat window
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false)
  const [inputMessage, setInputMessage] = useState()
  const [imageUrl, setImageUrl] = useState(null);
  const fileInputRef = useRef()
  const messagesEndRef = useRef(null);
  const [selectedInstantMsg, setSelectedInstantMsg] = useState({});
  const apiUrl = "https://webai-forex-backend-ashy.vercel.app/api/notice";
  const [instantMsges, setInstantMsges] = useState([]);

  const handleCityChange = async (rowId, value) => {
    console.log('User id: ', rowId)
    console.log('Msg: ', value)
    const message = value.msg;
    await axios.post(
      "https://webai-forex-backend-ashy.vercel.app/api/support/message",
      {
        userId: rowId,  // User ID from selected participant
        adminId: '6725e1ac5b3b65ea6c75e4d8',
        sender: 'admin',
        receiver: 'user',
        message: message,  // Pass the URL string if it's an image
      }
    )
      .then(() =>
        alert('Msg sent'))
      .catch(error => {
        console.error('Error fetching subadmins:', error);
      });
  };
  // Fetch user data for the table
  const fetchUserQueries = async () => {
    try {
      const response = await axios.get('https://webai-forex-backend-ashy.vercel.app/api/support/user-messages');
      const formattedData = response.data.map((item, index) => ({
        _id: item.userId,
        sr_no: index + 1,
        title: item.message,
        date: item.date.split(',')[0],
        name_: item.username,
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching user messages:', error);
    }
  };
  useEffect(() => {
    fetchUserQueries();
  }, []);
  const fetchMessages = async () => {
    if (!selectedUserId) return;
    try {
      const response = await axios.get(`https://webai-forex-backend-ashy.vercel.app/api/support/admin-user-history/${selectedUserId}/6725e1ac5b3b65ea6c75e4d8`);
      const chatMessages = response.data.map(msg => {
        const isImage = msg.message && (msg.message.startsWith('https://firebasestorage.googleapis.com') || /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(msg.message));
        return {
          type: isImage ? 'image' : 'text',
          direction: msg.sender === 'admin' ? 'outgoing' : 'incoming',
          payload: msg.message || '', // Default to an empty string if message is null
          sender: msg.sender, // Adjust as per role or API response if needed
          sentTime: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }), // Handle cases where timestamp might be null
        };
      });
      console.log('Api Messages: ', chatMessages)
      setMessages(chatMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };
  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 5000); // Poll every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [selectedUserId, username]);

  const handleUploadImageVideoOpen = () => {
    setOpenImageVideoUpload(preve => !preve)
  }
  const sendMessageToAPI = async (message) => {
    try {
      const messagePayload = message.type === 'image' ? message.payload.src : message.payload;

      await axios.post(
        "https://webai-forex-backend-ashy.vercel.app/api/support/message",
        {
          userId: selectedUserId,  // User ID from selected participant
          adminId: '6725e1ac5b3b65ea6c75e4d8',
          sender: 'admin',
          receiver: 'user',
          message: messagePayload,  // Pass the URL string if it's an image
        }
      );
    } catch (error) {
      console.error("Error sending message to API:", error);
    }
  };

  const sanitizeMessage = (message) => {
    if (typeof message !== 'string') return ''
    let sanitized = message.replace(/<[^>]+>/g, '')
    sanitized = sanitized.replace(/&nbsp;/g, ' ')
    sanitized = sanitized.replace(/&lt;/g, '<')
    sanitized = sanitized.replace(/&gt;/g, '>')
    sanitized = sanitized.replace(/&amp;/g, '&')
    sanitized = sanitized.replace(/&quot;/g, '"')
    sanitized = sanitized.replace(/&#39;/g, "'")
    sanitized = sanitized.replace(/\s+/g, ' ')
    sanitized = sanitized.trim()

    return sanitized
  }

  const handleAttachClick = () => {
    fileInputRef.current.click()
  }
  const uploadImage = async (file) => {
    const imageName = `chat-images/${Date.now()}-${file.name}`
    const imageRef = ref(storage, imageName)
    await uploadBytes(imageRef, file)
    return await getDownloadURL(imageRef)
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      try {
        const imageUrl = await uploadImage(file)
        const newMessage = {
          direction: 'outgoing',
          type: 'image',
          payload: {
            src: imageUrl,
            alt: file.name,
            width: 300,
            height: 200,
          },
          position: 'single',
          sender: 'Admin',
          sentTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
          imageName: file.name
        }
        setOpenImageVideoUpload(false)
        setMessages((prevMessages) => [...prevMessages, newMessage])
        await sendMessageToAPI(newMessage)
      } catch (error) {
        console.error("Error uploading image:", error)
      }
    }
  }

  const handleSend = async (e) => {
    e.preventDefault();
    const sanitizedMessage = sanitizeMessage(inputMessage)
    if (!sanitizedMessage && !imageUrl) return;
    const newMessage = {
      direction: 'outgoing',
      type: 'text',
      payload: sanitizedMessage,
      position: 'single',
      sender: 'Admin',
      sentTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
    }
    setMessages((prevMessages) => [...prevMessages, newMessage])
    await sendMessageToAPI(newMessage)
    setInputMessage('')
    setImageUrl(null);
  }

  const handleReplyClick = (userId, username) => {
    setSelectedUserId(userId);
    setUserName(username);
    // fetchChatMessages(userId); // Fetch chat messages for the selected user
  };


  useEffect(() => {
    const fetchInstantMessages = async () => {
      try {
        const response = await axios.get(apiUrl);
        if (response.data?.notices) {
          const formattedNotices = response.data.notices.map((notice) => ({
            id: notice._id,
            msg: notice.title,
          }));
          setInstantMsges(formattedNotices);
        }
      } catch (error) {
        console.error("Error fetching instant msges:", error);
      }
    };

    fetchInstantMessages();
  }, []);

  const columns = [
    {
      name: 'Sr no.',
      selector: (row) => row.sr_no,
      sortable: true,
      width: '110px',
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,
      width: '350px',
    },
    {
      name: 'Date',
      selector: (row) => row.date,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Name',
      selector: (row) => row.name_,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Reply user',
      cell: (row) => (
        <div className="flex space-x-4 justify-center py-4" style={{ alignItems: 'center', width: '120px' }}>
          <button
            title="Reply"
            className="text-blue-500 hover:text-blue-600 transition duration-200"
            onClick={() => { handleReplyClick(row._id, row.name_) }}
          >
            <FiEdit />
          </button>
        </div>
      ),
      width: '130px',
    },
    {
      name: 'Instant Reply',
      cell: (row) => (
        <Dropdown
          value={selectedInstantMsg[row._id] || null} // Set the value from the state for this specific row
          onChange={(e) => handleCityChange(row._id, e.value)} // Update the selected city for this row
          options={instantMsges}
          optionLabel="msg"
          placeholder="send a msg"
          className="w-full md:w-14rem h-8 items-center pl-2"
        />
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
        backgroundColor: '#f1f3f5',
        color: '#343a40',
        fontSize: '18px',
        fontWeight: 'bold',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f1f3f5',
        borderBottomWidth: '2px',
        borderBottomColor: '#dee2e6',
        borderBottomStyle: 'solid',
      },
    },
    headCells: {
      style: {
        color: '#343a40',
        fontWeight: 'bold',
        fontSize: '18px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    rows: {
      style: {
        minHeight: '48px',
        '&:not(:last-of-type)': {
          borderBottomWidth: '1px',
          borderBottomColor: '#dee2e6',
          borderBottomStyle: 'solid',
        },
      },
    },
    cells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
        fontSize: '16px',
      },
    },
    pagination: {
      style: {
        borderTopWidth: '1px',
        borderTopColor: '#dee2e6',
        borderTopStyle: 'solid',
        backgroundColor: '#f1f3f5',
        minHeight: 'auto',
      },
    },
  };

  const laptopPaginationStyles = `
        @media (min-width: 1024px) {
            .rdt_Pagination {
                min-height: 56px;
            }
        }
    `;


  return (
    <>
      <div className="min-h-[91vh] relative pt-1" style={{ backgroundImage: 'url(bg.svg)' }}>
        {selectedUserId ? (
          <div style={{ backgroundImage: `url(${backgroundImage})` }} className='bg-no-repeat bg-cover'>
            <header className='sticky top-0 mt-18 h-16 bg-white flex justify-between items-center px-4'>
              <div className='flex items-center gap-4'>
                <div onClick={() => setSelectedUserId(null)}>
                  <FaAngleLeft size={25} />
                </div>
                <div>
                  <h3 className='font-semibold text-lg my-0 text-ellipsis line-clamp-1'>{username ? `${username}` : 'Select a user to chat'}</h3>
                </div>
              </div>
            </header>
            <section className='h-[calc(100vh-128px)] overflow-x-hidden overflow-y-scroll scrollbar relative bg-slate-200 bg-opacity-50'>
              {messages.map((msg, index) => (
                <div className={`flex ${msg.direction === 'outgoing' ? 'justify-end' : 'flex-col gap-2'} py-2 mx-2`}>
                  <div className='flex flex-col gap-3 w-full max-w-2xl'>
                    <div
                      key={`message-${index}`}
                      className={`p-2 py-1 rounded-lg w-fit max-w-full md:max-w-md lg:max-w-lg ${msg.direction === 'incoming' ? 'bg-white' : 'ml-auto bg-teal-100'
                        }`}
                    >
                      {msg.type === 'text' ? (
                        <div>
                          <p className='px-3 text-lg'>{msg.payload}</p>
                          <p className='text-xs mt-1 ml-auto w-fit'>{msg.sentTime}</p>
                        </div>
                      ) : (
                        <div className='w-full relative max-w-[300px] md:max-w-lg'>
                          <img
                            src={typeof msg.payload === 'string' ? msg.payload : msg.payload.src}
                            className='w-full h-full object-scale-down'
                            alt="sent media"
                          />
                          <p className='text-xs mt-1 ml-auto w-fit'>{msg.sentTime}</p>
                        </div>
                      )}
                    </div>
                    <div ref={messagesEndRef} />
                  </div>
                </div>
              ))}
              {imageUrl && (
                <div className='w-full h-full sticky bottom-0 bg-slate-700 bg-opacity-30 flex justify-center items-center rounded overflow-hidden'>
                  <div className='w-fit p-2 absolute top-0 right-0 cursor-pointer hover:text-red-600' onClick={handleAttachClick}>
                    <IoClose size={30} />
                  </div>
                  <div className='bg-white p-3'>
                    <img
                      src={imageUrl}
                      alt='upload preview'
                      className='aspect-square w-full h-full max-w-sm m-2 object-scale-down'
                    />
                  </div>
                </div>
              )}
            </section>

            {/**send message */}
            <section className='h-16 bg-white flex items-center px-4'>
              <div className='relative'>
                <button
                  onClick={handleUploadImageVideoOpen}
                  className='flex justify-center items-center w-11 h-11 rounded-full hover:bg-[#05adb5] hover:text-white'
                >
                  <FaPlus size={20} />
                </button>
                {openImageVideoUpload && (
                  <div className='bg-white shadow rounded absolute bottom-14 w-36 p-2'>
                    <form>
                      <label htmlFor='uploadImage' className='flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer'>
                        <div className='text-primary'>
                          <FaImage size={18} />
                        </div>
                        <p>Image</p>
                      </label>
                      <input
                        type='file'
                        id='uploadImage'
                        onChange={handleFileChange}
                        className='hidden'
                        accept="image/*"
                      />
                    </form>
                  </div>
                )}
              </div>

              {/**input box */}
              <form className='h-full w-full flex gap-2'
              >
                <input
                  type='text'
                  placeholder='Type here message...'
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  className='py-1 px-4 outline-none w-full h-full'
                />
                <button className='text-primary hover:text-secondary' onClick={handleSend}>
                  <IoMdSend size={28} />
                </button>
              </form>

            </section>
          </div>
        ) : (
          // Data Table
          <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', marginTop: '10px' }}>
            <style>{laptopPaginationStyles}</style>
            <h1 className="font-bold text-[24px] leading-[24px] text-[#23272E] mt-16 md:mt-0">Support</h1>
            <div className="flex flex-col md:flex-row justify-end mb-5 gap-3 mt-8">
              <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
                <input
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={data}
              pagination
              highlightOnHover
              pointerOnHover
              dense
              customStyles={customStyles}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Support;
