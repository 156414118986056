import { useEffect, useState, KeyboardEvent } from "react"
import { FaSave } from "react-icons/fa"
import { getAuth, updatePassword } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import ReactPasswordChecklist from "react-password-checklist"
import { Bounce, toast, ToastContainer } from "react-toastify"
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"

export default function Settings() {
  const userData = JSON.parse(localStorage.getItem('user'))
  const auth = getAuth() // Firebase auth instance
  const navigate = useNavigate()
  const [validPass, setValidPass] = useState();
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [formData, setFormData] = useState({
    email: "admin123@gmail.com",
  })
  const [password, setPassword] = useState(userData.admin.password)
  const [tags, setTags] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter' && inputValue.trim()) {
  //     e.preventDefault()
  //     setTags([...tags, inputValue.trim()])
  //     setInputValue('')
  //   }
  // }

  // const removeTag = (tagToRemove) => {
  //   setTags(tags.filter(tag => tag !== tagToRemove))
  // }

  useEffect(() => {
    console.log('User Data:', userData)
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      // 1. Update password in Firebase
      if (password !== userData.admin.password) {
        await updatePassword(auth.currentUser, password)
      }

      // 2. Update password in backend
      const response = await fetch(
        "https://webai-forex-backend-ashy.vercel.app/api/admin/6726733ffea94697da4c29de/update-password",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newPassword: password }),
        }
      )

      const data = await response.json()
      if (response.ok) {
        toastSuccess("Password updated successfully. Please log in again.")

        // 3. Log out the user and redirect to login page
        localStorage.removeItem("user") // Clear user data from localStorage
        navigate('/login') // Redirect to login page
      } else {
        toastError(data.message || "An error occurred while updating your password.")
      }
    } catch (error) {
      console.error("Error updating password:", error)
      toastError("Something went wrong. Please try again later.")
    }
  }

  const toastSuccess = (text) => toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
  const toastError = (text) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  const fetchTags = async () => {
    try {
      const response = await axios.get('https://webai-forex-backend-ashy.vercel.app/api/notice')
      setTags(response.data.notices)
      setError('')
    } catch (err) {
      setError('Failed to fetch tags')
      console.error('Error fetching tags:', err)
    } finally {
      setLoading(false)
    }
  }

  // Add a new message
  const addMessage = async (message) => {
    try {
      const response = await axios.post(
        "https://webai-forex-backend-ashy.vercel.app/api/notice/send",
        { title: message },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setTags((prevTags) => [...prevTags, response.data.notice.title]); // Add the new message to the tags
    } catch (error) {
      console.error("Error adding message:", error);
    }
  };

  const removeTag = async (tagId) => {
    setLoading(true)
    try {
      await axios.delete(`https://webai-forex-backend-ashy.vercel.app/api/notice/${tagId}`)
      setTags(tags.filter(tag => tag._id !== tagId))
      setError('')
    } catch (err) {
      setError('Failed to delete tag')
      console.error('Error deleting tag:', err)
    } finally {
      setLoading(false)
    }
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault()
      setLoading(true)
      try {
        const response = await axios.post('https://webai-forex-backend-ashy.vercel.app/api/notice/send', {
          title: inputValue.trim()
        })
        setTags([...tags, response.data.notice])
        setInputValue('')
        setError('')
      } catch (err) {
        setError('Failed to add tag')
        console.error('Error adding tag:', err)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchTags()
  }, [])

  return (
    <>
      <div className='min-h-[91vh] relative pt-1' style={{ backgroundImage: 'url(bg.svg)' }}>
        <div className="p-4 md:p-6 mt-20 md:mt-0">
          <h1 className="text-2xl md:text-[43.49px] leading-[1.2] font-bold mb-4 md:mb-7 text-[#4F4F4F]">
            General Settings
          </h1>
          <form className="space-y-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={true}
                  className="w-full outline-none border border-gray-300 px-3 py-2 md:py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full outline-none border border-gray-300 px-3 py-2 md:py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                  </span>
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2"
                >
                  Enter pre writtern messages
                </label>
                <div className="w-full outline-none border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <div
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "8px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    {tags.map((tag) => (
                      <span key={tag._id} style={{
                        backgroundColor: '#3b82f6',
                        color: 'white',
                        padding: '5px 10px',
                        borderRadius: '16px',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        {tag.title}
                        <button
                          type="button"
                          onClick={() => removeTag(tag._id)}
                          style={{
                            marginLeft: '4px',
                            background: 'none',
                            border: 'none',
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold'
                          }}
                        >
                          ×
                        </button>
                      </span>
                    ))}
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{
                        flex: "1",
                        border: "none",
                        outline: "none",
                        fontSize: "14px",
                        minWidth: "120px",
                        backgroundColor: "transparent",
                      }}
                      placeholder="Type and press Enter..."
                    />
                  </div>
                </div>

              </div>
              <ReactPasswordChecklist
                rules={["minLength", "specialChar", "number", "capital"]}
                minLength={8}
                value={password}
                messages={{
                  minLength: "The password is more than 8 characters.",
                  specialChar: "The password has special characters.",
                  number: "The password has a number.",
                  capital: "The password has a capital letter.",
                }}
                onChange={(isValid) => setValidPass(isValid)}
              />
            </div>
            <div className="flex space-x-4">
              {
                validPass ?
                  <button
                    type="submit"
                    className="bg-[#3EBA59] text-white rounded-md px-4 md:px-[19.03px] py-3 md:py-[16.31px] text-sm md:text-[19.03px] leading-[1.2] transition-transform duration-200 ease-in-out transform hover:scale-90 hover:bg-green-500 hover:shadow-lg hover:font-semibold flex items-center justify-center"
                    onClick={() => handleSubmit()}
                  >
                    <FaSave className="mr-2" />
                    Save
                  </button>

                  :
                  <button
                    type="submit"
                    className="bg-[#b2bec3] text-white rounded-md px-4 md:px-[19.03px] py-3 md:py-[16.31px] text-sm md:text-[19.03px] leading-[1.2] transition-transform duration-200 ease-in-out transform hover:scale-90 hover:shadow-lg hover:font-semibold flex items-center justify-center"
                    disabled={true}
                  >
                    <FaSave className="mr-2" />
                    Save
                  </button>
              }
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
