import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Navbar, Sidebar } from "./Components";
import "./App.css";
import { useStateContext } from "./Contexts/ContextProvider";
import Courses from "./Pages/Courses";
import FundedAccount from "./Pages/FundedAccount";
import Analysis from "./Pages/Analysis";
import MembersChat from "./Pages/MembersChat";
import DepartmentDashboard from "./Pages/Department";
import SubAdmin from "./Pages/SubAdmin";
import SuspendedAccount from "./Pages/SuspendedAccount";
import Verification from "./Pages/Verification";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import '@progress/kendo-theme-default/dist/all.css';
import Support from "./Pages/Support";
import Settings from "./Pages/Settings";
import Notifications from "./Pages/Notifications";
import Signals from "./Pages/Signals";
import { PrimeReactProvider } from 'primereact/api';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    setActiveMenu,
    role,
    setRole,
    isAuthenticated,
    setIsAuthenticated,
  } = useStateContext();
  // Hardcoded users
  const users = [
    { username: "user1@gmail.com", password: "user1pass", role: "user" },
    { username: "user2@gmail.com", password: "user2pass", role: "user" },
    { username: "admin@gmail.com", password: "adminpass", role: "admin" },
  ];

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }

    // Check if the user is logged in
    const loggedIn = localStorage.getItem("isAuthenticated");
    const userRole = localStorage.getItem("role");
    if (loggedIn) {
      setIsAuthenticated(true);
      setRole(userRole);
    }
  }, []);
  const handleLogout = () => {
    setIsAuthenticated(false);
    setRole("");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("role");
    setActiveMenu(true); // Reset active menu on logout
  };

  const value = {
    appendTo: 'self',
  };

  return (
    <PrimeReactProvider value={value}>
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <BrowserRouter>
          {/* Main Layout */}
          {isAuthenticated && (
            <div className="flex relative dark:bg-main-dark-bg">
              {activeMenu && (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                  <Sidebar handleLogout={handleLogout} />
                </div>
              )}
              <div
                className={
                  activeMenu
                    ? "dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full"
                    : "bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2"
                }
              >
                <div className="static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                  <Navbar />
                </div>
                {/* Authenticated Routes */}
                <Routes>
                  <Route path="/membersChat" element={<Signals />} />
                  <Route path="/courses" element={<Courses />} />
                  <Route path="/department" element={<DepartmentDashboard />} />
                  {/* <Route path="/fundedaccount" element={<FundedAccount />} /> */}
                  <Route path="/analysis" element={<Analysis />} />
                  <Route path="/subadmin" element={<SubAdmin />} />
                  <Route path="/suspendedaccount" element={<SuspendedAccount />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/verification" element={<Verification />} />
                  <Route path="*" element={<Navigate to="/membersChat" />} />
                </Routes>
              </div>
            </div>
          )}

          {/* Unauthenticated Routes */}
          {!isAuthenticated && (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </BrowserRouter>
      </div>
    </PrimeReactProvider>
  );
};

export default App;

// import React, { useEffect, useState } from "react";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { Navbar, Sidebar } from "./Components";
// import "./App.css";
// import { useStateContext } from "./Contexts/ContextProvider";
// import Courses from "./Pages/Courses";
// import FundedAccount from "./Pages/FundedAccount";
// import Analysis from "./Pages/Analysis";
// import MembersChat from "./Pages/MembersChat";
// import DepartmentDashboard from "./Pages/Department";
// import SubAdmin from "./Pages/SubAdmin";
// import SuspendedAccount from "./Pages/SuspendedAccount";
// import Verification from "./Pages/Verification";
// import Login from "./Pages/Login";
// import SignUp from "./Pages/SignUp";
// import '@progress/kendo-theme-default/dist/all.css';

// const App = () => {
//   const {
//     setCurrentColor,
//     setCurrentMode,
//     currentMode,
//     activeMenu,
//     setActiveMenu,
//     role,
//     setRole,
//   } = useStateContext();

//   useEffect(() => {
//     const currentThemeColor = localStorage.getItem("colorMode");
//     const currentThemeMode = localStorage.getItem("themeMode");
//     if (currentThemeColor && currentThemeMode) {
//       setCurrentColor(currentThemeColor);
//       setCurrentMode(currentThemeMode);
//     }
//   }, []);

//   const handleLogout = () => {
//     setRole("");
//     localStorage.removeItem("isAuthenticated");
//     localStorage.removeItem("role");
//     setActiveMenu(true); // Reset active menu on logout
//   };

//   return (
//     <div className={currentMode === "Dark" ? "dark" : ""}>
//       <BrowserRouter>
//         <div className="flex relative dark:bg-main-dark-bg">
//           {activeMenu && (
//             <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
//               <Sidebar/>
//             </div>
//           )}
//           <div
//             className={
//               activeMenu
//                 ? "dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full"
//                 : "bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2"
//             }
//           >
//             <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
//               <Navbar />
//             </div>
//             {/* Simplified Routes */}
//             <Routes>
//               <Route path="/membersChat" element={<MembersChat />} />
//               <Route path="/courses" element={<Courses />} />
//               <Route path="/department" element={<DepartmentDashboard />} />
//               <Route path="/fundedaccount" element={<FundedAccount />} />
//               <Route path="/analysis" element={<Analysis />} />
//               <Route path="/subadmin" element={<SubAdmin />} />
//               <Route path="/suspendedaccount" element={<SuspendedAccount />} />
//               <Route path="/verification" element={<Verification />} />
//               <Route path="/login" element={<Login />} />
//               <Route path="/signup" element={<SignUp />} />
//               <Route path="*" element={<Navigate to="/membersChat" />} />
//             </Routes>
//           </div>
//         </div>
//       </BrowserRouter>
//     </div>
//   );
// };

// export default App;
