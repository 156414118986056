// import React, { useState } from 'react';
// import AddSubAdmin from './AddSubAdmin';
// import axios from 'axios';
// import { Bounce, toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import ViewSubAdminModal from './ViewSubAdminModal';

// const Button = ({ children, onClick, className }) => (
//   <button
//     onClick={onClick}
//     className={`px-4 py-2 rounded ${className}`}
//     style={{ cursor: 'pointer' }}
//   >
//     {children}
//   </button>
// );

// const Input = ({ type, placeholder, value, onChange, className }) => (
//   <input
//     type={type}
//     placeholder={placeholder}
//     value={value}
//     onChange={onChange}
//     className={`border rounded px-3 py-2 ${className}`}
//   />
// );

// const Card = ({ children }) => (
//   <div className="border rounded-[25.34px] border-[#B4CAF4] bg-white bg-opacity-50 shadow-sm p-4">
//     {children}
//   </div>
// );

// export default function DepartmentDashboard() {
//   const [departments, setDepartments] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showAddSubAdmin, setShowAddSubAdmin] = useState(false);
//   const [selectedDepartment, setSelectedDepartment] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [departmentName, setDepartmentName] = useState('');
//   const [updateDepartmentName, setUpdateDepartmentName] = useState('');
//   const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
//   const [showViewSubAdmin, setShowViewSubAdmin] = useState(false);
//   const [subAdmins, setSubAdmins] = useState([]);


//   const fetchDepartments = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments`);
//       setDepartments(response.data);
//     } catch (error) {
//       toastError('Error fetching departments:', error);
//     }
//   };

//   fetchDepartments();


//   const filteredDepartments = departments.filter(dept =>
//     dept.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setDepartmentName('');
//   };

//   const handleAddDepartment = async () => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/departments/add`,
//         { name: departmentName }
//       );
//       handleCloseModal();
//     } catch (error) {
//       toastError('Error adding department:', error);
//     }
//   };

//   const handleAddSubAdmin = (department) => {
//     setSelectedDepartment(department);
//     setShowAddSubAdmin(true);
//   };

//   const handleOpenUpdateModal = (department) => {
//     setSelectedDepartment(department);
//     setUpdateDepartmentName(department.name);
//     setIsUpdateModalOpen(true);
//   };

//   const handleViewSubAdmin = async (departmentId) => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments/${departmentId}/subadmins`);
//       setSubAdmins(response.data);
//       console.log(response.data);
//       setShowViewSubAdmin(true);
//     } catch (error) {
//       console.error('Error fetching sub-admins:', error);
//     }
//   };


//   const handleUpdateDepartment = async () => {
//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_BASE_URL}/api/departments/${selectedDepartment._id}`,
//         { name: updateDepartmentName }
//       );
//       setDepartments((prevDepartments) =>
//         prevDepartments.map((dept) =>
//           dept._id === response.data._id ? response.data : dept
//         )
//       );
//       handleCloseUpdateModal();
//     } catch (error) {
//       toastError('Error updating department:', error);
//     }
//   };

//   const handleCloseUpdateModal = () => {
//     setIsUpdateModalOpen(false);
//     setUpdateDepartmentName('');
//     setSelectedDepartment(null);
//   };

//   const toastSuccess = (text) => toast.success(text, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "light",
//     transition: Bounce,
//   });

//   const toastError = (text) => toast.error(text, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "light",
//     transition: Bounce,
//   });

//   return (
//     <>
//     <div className='min-h-screen ' style={{ backgroundImage: 'url(bg.svg)'}}>
//     <div className="max-w-full mx-auto p-6 mt-16 md:mt-0">
//       <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>DEPARTMENTS</h1>
//       <div className="flex flex-col md:flex-row justify-between items-center mb-6">
//         {/* <div className="relative w-full md:w-64 mb-5 md:mb-0">
//           <Input
//             type="text"
//             placeholder="Search by Name"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             className="pl-10 w-full"
//           />
//           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', width: '20px', height: '20px', color: '#9CA3AF' }}>
//             <circle cx="11" cy="11" r="8"></circle>
//             <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
//           </svg>
//         </div> */}
//         <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//           <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//             <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//               <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//               <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//             </svg>
//           </div>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//           />
//         </div>
//         <div>
//           <button
//             onClick={handleOpenModal}
//             className=" border border-[#B4CAF4] rounded-[6px] px-4 py-2 flex items-center hover:bg-[#0760FF] hover:bg-opacity-25 cursor-pointer"
//           >
//             <span className='text-[#0760FF] hover:text-blue-800 text-[15px] leading-[17.63px] p-2 font-medium hover:font-semibold cursor-pointer'>Add Department</span>
//             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <path d="M13.3103 2.70044C12.6274 2.0173 11.6628 1.91016 10.5245 1.91016H5.46196C4.33711 1.91016 3.37282 2.0173 2.68968 2.70016C2.00654 3.38301 1.90625 4.34073 1.90625 5.4593V10.5216C1.90625 11.6667 2.00682 12.6176 2.68968 13.3007C3.37254 13.9839 4.33711 14.0907 5.48225 14.0907H10.5245C11.6628 14.0907 12.6274 13.9836 13.3103 13.3007C13.9931 12.6179 14.0937 11.6667 14.0937 10.5216V5.4793C14.0937 4.33416 13.9934 3.37673 13.3103 2.70044ZM7.99996 11.9276C7.85975 11.9274 7.72533 11.8716 7.62616 11.7725C7.52698 11.6733 7.47112 11.5389 7.47082 11.3987V8.52616H4.60511C4.31025 8.52616 4.07596 8.28501 4.07596 8.00387C4.07596 7.72273 4.31025 7.46816 4.60482 7.46816H7.47082V4.60187C7.47082 4.30073 7.70511 4.06616 7.99996 4.06616C8.30139 4.06616 8.52911 4.30044 8.52911 4.60187V7.46816H11.4017C11.6965 7.46816 11.9308 7.72244 11.9308 8.00387C11.9308 8.28501 11.6965 8.52616 11.4017 8.52616H8.52911V11.399C8.52953 11.4686 8.51612 11.5376 8.48967 11.602C8.46323 11.6663 8.42426 11.7248 8.37504 11.774C8.32582 11.8232 8.26732 11.8621 8.20293 11.8885C8.13854 11.915 8.06956 11.928 7.99996 11.9276Z" fill="#04CF31" />
//             </svg>

//           </button>

//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//               <div className="bg-white p-6 rounded shadow-lg w-80">
//                 <h2 className="text-lg font-semibold mb-4">Add Department</h2>
//                 <input
//                   type="text"
//                   placeholder="Department Name"
//                   value={departmentName}
//                   onChange={(e) => setDepartmentName(e.target.value)}
//                   className="w-full p-2 border border-gray-300 rounded mb-4"
//                 />
//                 <div className="flex justify-end">
//                   <button
//                     onClick={handleAddDepartment}
//                     className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
//                   >
//                     Add
//                   </button>
//                   <button
//                     onClick={handleCloseModal}
//                     className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
//                   >
//                     Cancel
//                   </button>
//                   <ToastContainer
//                     position="top-right"
//                     autoClose={5000}
//                     hideProgressBar={false}
//                     newestOnTop={false}
//                     closeOnClick
//                     rtl={false}
//                     pauseOnFocusLoss
//                     draggable
//                     pauseOnHover
//                     theme="light"
//                   />
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
//         {filteredDepartments.map((dept, index) => (
//           <Card key={index}>
//             <div className="min-h-[150px] flex flex-col justify-between">
//               <div className="flex justify-between items-center mb-2">
//                 <h3 className="m-0 text-[19.64px] font-medium leading-[23.08px] text-[#060101]">{dept.name}</h3>
//                 <svg
//                   onClick={() => handleOpenUpdateModal(dept)}
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                   stroke="currentColor"
//                   strokeWidth="2"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   style={{ width: '18px', height: '18px', cursor: 'pointer', color: '#6B7280' }}
//                 >
//                   <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
//                   <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
//                 </svg>
//               </div>
//               <div className='flex justify-between mt-[10] flex-col md:flex-row gap-4 rounded-[6.55px] '>
//                 <Button onClick={() => handleAddSubAdmin(dept)} className="bg-[#3EBA59] text-white hover:bg-green-500 hover:scale-95">Add Sub-Admin</Button>
//                 <Button onClick={() => handleViewSubAdmin(dept._id)} className="border border-gray-300 hover:bg-gray-100 hover:scale-95 text-[#3EBA59] font-semibold">View SubAdmin</Button>
//               </div>
//             </div>
//           </Card>
//         ))}
//       </div>

//       {showAddSubAdmin && (
//         <AddSubAdmin
//           onClose={() => setShowAddSubAdmin(false)}
//           departmentId={selectedDepartment._id}
//         />
//       )}

//       {showViewSubAdmin && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white rounded-lg shadow-lg w-[90%] md:w-[90%] h-5/6 overflow-auto p-4">
//             <ViewSubAdminModal
//               subAdmins={subAdmins}
//               onClose={() => setShowViewSubAdmin(false)}
//             />
//             <button
//               onClick={() => setShowViewSubAdmin(false)}
//               className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
//             >
//               &times;
//             </button>
//           </div>
//         </div>
//       )}

//       {isUpdateModalOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//           <div className="bg-white p-6 rounded shadow-lg w-80">
//             <h2 className="text-lg font-semibold mb-4">Update Department</h2>
//             <input
//               type="text"
//               placeholder="Department Name"
//               value={updateDepartmentName}
//               onChange={(e) => setUpdateDepartmentName(e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded mb-4"
//             />
//             <div className="flex justify-end">
//               <button
//                 onClick={handleUpdateDepartment}
//                 className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
//               >
//                 Update
//               </button>
//               <button
//                 onClick={handleCloseUpdateModal}
//                 className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//     </div>
//     </>
//   );
// }


// import React, { useState } from 'react';
// import AddSubAdmin from './AddSubAdmin';
// import axios from 'axios';
// import { Bounce, toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { storage } from './firebaseConfig';
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import ViewSubAdminModal from './ViewSubAdminModal';
// import { FiUpload, FiEye, FiTrash } from 'react-icons/fi';

// const Button = ({ children, onClick, className }) => (
//   <button
//     onClick={onClick}
//     className={`px-4 py-2 rounded ${className}`}
//     style={{ cursor: 'pointer' }}
//   >
//     {children}
//   </button>
// );

// const Input = ({ type, placeholder, value, onChange, className }) => (
//   <input
//     type={type}
//     placeholder={placeholder}
//     value={value}
//     onChange={onChange}
//     className={`border rounded px-3 py-2 ${className}`}
//   />
// );

// const Card = ({ children }) => (
//   <div className="border rounded-[25.34px] border-[#B4CAF4] bg-white bg-opacity-50 shadow-sm p-4">
//     {children}
//   </div>
// );

// export default function DepartmentDashboard() {
//   const [departments, setDepartments] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showAddSubAdmin, setShowAddSubAdmin] = useState(false);
//   const [selectedDepartment, setSelectedDepartment] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [departmentName, setDepartmentName] = useState('');
//   const [updateDepartmentName, setUpdateDepartmentName] = useState('');
//   const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
//   const [showViewSubAdmin, setShowViewSubAdmin] = useState(false);
//   const [subAdmins, setSubAdmins] = useState([]);
//   const [imageFile, setImageFile] = useState(null);
//   const [imageUrl, setImageUrl] = useState(null);



//   const fetchDepartments = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments`);
//       setDepartments(response.data);
//     } catch (error) {
//       toast.error('Error fetching departments:', {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//         transition: Bounce,
//         });
//     }
//   };

//   fetchDepartments();


//   const filteredDepartments = departments.filter(dept =>
//     dept.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setDepartmentName('');
//     setImageUrl('')
//   };

//   const handleAddDepartment = async () => {
//     try {
//       if (imageFile) {
//         let imageUrl = "";
//         const imageRef = ref(storage, `departments/${imageFile.name}`);
//         await uploadBytes(imageRef, imageFile);
//         imageUrl = await getDownloadURL(imageRef);
//       }

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/departments/add`,
//         { name: departmentName, imageUrl: imageUrl }
//       );

//       handleCloseModal();
//       toast.success('Department added successfully!', {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//         transition: Bounce,
//         });
//     } catch (error) {
//       toast.error('Error adding department:', {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//         transition: Bounce,
//         });
//     }
//   };

//   const handleImageUpload = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       try {
//         const imageRef = ref(storage, `departmentImages/${file.name}`);
//         await uploadBytes(imageRef, file);
//         const url = await getDownloadURL(imageRef);
//         setImageUrl(url);
//         console.log("Image URL:", url);
//       } catch (error) {
//         console.error("Error uploading image:", error);
//       }
//     }
//   };

//   const handleRemoveImage = () => {
//     setImageUrl(null);
//   };


//   const handleAddSubAdmin = (department) => {
//     setSelectedDepartment(department);
//     setShowAddSubAdmin(true);
//   };

//   const handleOpenUpdateModal = (department) => {
//     setSelectedDepartment(department);
//     setUpdateDepartmentName(department.name);
//     setIsUpdateModalOpen(true);
//   };

//   const handleViewSubAdmin = async (departmentId) => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments/${departmentId}/subadmins`);
//       setSubAdmins(response.data);
//       console.log(response.data);
//       setShowViewSubAdmin(true);
//     } catch (error) {
//       console.error('Error fetching sub-admins:', error);
//     }
//   };


//   const handleUpdateDepartment = async () => {
//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_BASE_URL}/api/departments/${selectedDepartment._id}`,
//         { name: updateDepartmentName }
//       );
//       setDepartments((prevDepartments) =>
//         prevDepartments.map((dept) =>
//           dept._id === response.data._id ? response.data : dept
//         )
//       );
//       handleCloseUpdateModal();
//     } catch (error) {
//       toast.error('Error updating department:', {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//         transition: Bounce,
//         });
//     }
//   };

//   const handleDeleteDept = async (id) => {
//     try {
//       await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/departments/${id}`);
//       setDepartments(departments.filter(dept => dept._id !== id));
//       toastSuccess("Course deleted successfully.");
//     } catch (error) {
//       toastError("Error deleting course:", error);
//     }
//   }

//   const handleCloseUpdateModal = () => {
//     setIsUpdateModalOpen(false);
//     setUpdateDepartmentName('');
//     setSelectedDepartment(null);
//   };

//   const toastSuccess = (text) => toast.success(text, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "light",
//     transition: Bounce,
//   });

//   const toastError = (text) => toast.error(text, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "light",
//     transition: Bounce,
//   });

//   return (
//     <>
//       <div className='min-h-[91vh] ' style={{ backgroundImage: 'url(bg.svg)' }}>
//       <ToastContainer />
//         <div className="max-w-full mx-auto p-6 mt-16 md:mt-0">
//           <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>DEPARTMENTS</h1>
//           <div className="flex flex-col md:flex-row justify-between items-center mb-6">
//             <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//               <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//                 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//                   <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                   <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                 </svg>
//               </div>
//               <input
//                 type="text"
//                 placeholder="Search..."
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//               />
//             </div>
//             <div>
//               <button
//                 onClick={handleOpenModal}
//                 className=" border border-[#B4CAF4] rounded-[6px] px-4 py-2 flex items-center hover:bg-[#0760FF] hover:bg-opacity-25 cursor-pointer"
//               >
//                 <span className='text-[#0760FF] hover:text-blue-800 text-[15px] leading-[17.63px] p-2 font-medium hover:font-semibold cursor-pointer'>Add Department</span>
//                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M13.3103 2.70044C12.6274 2.0173 11.6628 1.91016 10.5245 1.91016H5.46196C4.33711 1.91016 3.37282 2.0173 2.68968 2.70016C2.00654 3.38301 1.90625 4.34073 1.90625 5.4593V10.5216C1.90625 11.6667 2.00682 12.6176 2.68968 13.3007C3.37254 13.9839 4.33711 14.0907 5.48225 14.0907H10.5245C11.6628 14.0907 12.6274 13.9836 13.3103 13.3007C13.9931 12.6179 14.0937 11.6667 14.0937 10.5216V5.4793C14.0937 4.33416 13.9934 3.37673 13.3103 2.70044ZM7.99996 11.9276C7.85975 11.9274 7.72533 11.8716 7.62616 11.7725C7.52698 11.6733 7.47112 11.5389 7.47082 11.3987V8.52616H4.60511C4.31025 8.52616 4.07596 8.28501 4.07596 8.00387C4.07596 7.72273 4.31025 7.46816 4.60482 7.46816H7.47082V4.60187C7.47082 4.30073 7.70511 4.06616 7.99996 4.06616C8.30139 4.06616 8.52911 4.30044 8.52911 4.60187V7.46816H11.4017C11.6965 7.46816 11.9308 7.72244 11.9308 8.00387C11.9308 8.28501 11.6965 8.52616 11.4017 8.52616H8.52911V11.399C8.52953 11.4686 8.51612 11.5376 8.48967 11.602C8.46323 11.6663 8.42426 11.7248 8.37504 11.774C8.32582 11.8232 8.26732 11.8621 8.20293 11.8885C8.13854 11.915 8.06956 11.928 7.99996 11.9276Z" fill="#04CF31" />
//                 </svg>

//               </button>
//               {isModalOpen && (
//                 <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//                   <div className="bg-white p-6 rounded shadow-lg w-[50%]">
//                     <h2 className="text-lg font-semibold mb-4">Add Department</h2>
//                     <input
//                       type="text"
//                       placeholder="Department Name"
//                       value={departmentName}
//                       onChange={(e) => setDepartmentName(e.target.value)}
//                       className="w-full p-2 border border-gray-300 rounded mb-4"
//                     />
//                     <div className='flex items-center'>
//                       <label className="relative cursor-pointer bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-600 transition-all">
//                         <span className="text-sm font-medium">Choose Image</span>
//                         <input
//                           type="file"
//                           accept="image/*"
//                           onChange={handleImageUpload}
//                           className="absolute inset-0 opacity-0 cursor-pointer"
//                         />
//                       </label>

//                       {imageUrl && (
//                         <div className="mt-2">
//                           <button
//                             onClick={() => window.open(imageUrl, '_blank')}
//                             className="text-blue-500 hover:text-blue-700"
//                             aria-label="View Image"
//                           >
//                             <FiEye className="h-6 w-6 mx-2" />
//                           </button>
//                           <button
//                             onClick={handleRemoveImage}
//                             className="text-red-500 hover:text-red-700"
//                             aria-label="Remove Image"
//                           >
//                             <FiTrash className="h-6 w-6" />
//                           </button>
//                         </div>
//                       )}
//                     </div>
//                     <div className="flex justify-end">
//                       {
//                         departmentName && imageUrl ? (
//                           <button
//                             onClick={handleAddDepartment}
//                             className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
//                           >
//                             Add
//                           </button>) : (
//                           <button
//                             className="bg-gray-300 text-white px-4 py-2 rounded mr-2"
//                             disabled={true}
//                           >
//                             Add
//                           </button>
//                         )
//                       }
//                       <button
//                         onClick={handleCloseModal}
//                         className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
//                       >
//                         Cancel
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
//             {filteredDepartments.map((dept, index) => (
//               <Card key={index}>
//                 <div className="min-h-[150px] flex flex-col justify-between">
//                   <div className="flex justify-between items-center mb-2">
//                     <h3 className="m-0 text-[19.64px] font-medium leading-[23.08px] text-[#060101]">{dept.name}</h3>
//                     <div className='flex flex-row w-[12%] justify-between'>
//                       <svg
//                         onClick={() => handleOpenUpdateModal(dept)}
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         style={{ width: '18px', height: '18px', cursor: 'pointer', color: '#6B7280' }}
//                       >
//                         <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
//                         <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
//                       </svg>
//                       <button>
//                         <FiTrash
//                           className="cursor-pointer text-red-500 hover:text-red-700"
//                           onClick={() => handleDeleteDept(dept._id)}
//                         />
//                       </button>
//                       {/* <button>
//                         <FiTrash
//                           className="cursor-pointer text-red-500 hover:text-red-700"
//                           onClick={() => handleDeleteDept(dept._id)}
//                         />
//                       </button> */}
//                     </div>
//                   </div>
//                   <img src={dept.image} alt={dept.name} className="w-full h-40 my-3 object-cover rounded mb-6" />
//                   <div className='flex justify-between mt-[10] flex-col md:flex-row gap-4 rounded-[6.55px] '>
//                     <Button onClick={() => handleAddSubAdmin(dept)} className="bg-[#3EBA59] text-white hover:bg-green-500 hover:scale-95">Add Sub-Admin</Button>
//                     <Button onClick={() => handleViewSubAdmin(dept._id)} className="border border-gray-300 hover:bg-gray-100 hover:scale-95 text-[#3EBA59] font-semibold">View SubAdmin</Button>
//                   </div>
//                 </div>
//               </Card>
//             ))}
//           </div>

//           {showAddSubAdmin && (
//             <AddSubAdmin
//               onClose={() => setShowAddSubAdmin(false)}
//               departmentId={selectedDepartment._id}
//             />
//           )}

//           {showViewSubAdmin && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//               <div className="bg-white rounded-lg shadow-lg w-[90%] md:w-[90%] h-5/6 overflow-auto p-4">
//                 <ViewSubAdminModal
//                   subAdmins={subAdmins}
//                   onClose={() => setShowViewSubAdmin(false)}
//                 />
//                 <button
//                   onClick={() => setShowViewSubAdmin(false)}
//                   className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
//                 >
//                   &times;
//                 </button>
//               </div>
//             </div>
//           )}

//           {isUpdateModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//               <div className="bg-white p-6 rounded shadow-lg w-80">
//                 <h2 className="text-lg font-semibold mb-4">Update Department</h2>
//                 <input
//                   type="text"
//                   placeholder="Department Name"
//                   value={updateDepartmentName}
//                   onChange={(e) => setUpdateDepartmentName(e.target.value)}
//                   className="w-full p-2 border border-gray-300 rounded mb-4"
//                 />
//                 <div className="flex justify-end">
//                   <button
//                     onClick={handleUpdateDepartment}
//                     className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
//                   >
//                     Update
//                   </button>
//                   <button
//                     onClick={handleCloseUpdateModal}
//                     className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }


import React, { useState } from 'react';
import AddSubAdmin from './AddSubAdmin';
import axios from 'axios';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storage } from './firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ViewSubAdminModal from './ViewSubAdminModal';
import { FiUpload, FiEye, FiTrash } from 'react-icons/fi';
import EditRoleModal from "./EditRoleModal";


const Button = ({ children, onClick, className }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded ${className}`}
    style={{ cursor: 'pointer' }}
  >
    {children}
  </button>
);

const Input = ({ type, placeholder, value, onChange, className }) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={`border rounded px-3 py-2 ${className}`}
  />
);

const Card = ({ children }) => (
  <div className="border rounded-[25.34px] border-[#B4CAF4] bg-white bg-opacity-50 shadow-sm p-4">
    {children}
  </div>
);

export default function DepartmentDashboard() {
  const [departments, setDepartments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddSubAdmin, setShowAddSubAdmin] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departmentName, setDepartmentName] = useState('');
  const [updateDepartmentName, setUpdateDepartmentName] = useState('');
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [showViewSubAdmin, setShowViewSubAdmin] = useState(false);
  const [subAdmins, setSubAdmins] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isEditRoleModalOpen, setEditRoleModalOpen] = useState(false);


  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments`);
      setDepartments(response.data);
    } catch (error) {
      toast.error('Error fetching departments:', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  fetchDepartments();


  const filteredDepartments = departments.filter(dept =>
    dept.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDepartmentName('');
    setImageUrl('')
  };


  // const handleEditRole = (dept) => {
  //   setSelectedDepartment(dept);
  //   setEditRoleModalOpen(true);
  // };

  // const handleCloseEditRoleModal = () => {
  //   setEditRoleModalOpen(false);
  //   setSelectedDepartment(null);
  // };

  // const handleSaveRoles = (deptId, updatedRoles) => {
  //   console.log("Updated Roles for Dept:", deptId, updatedRoles);
  //   // Call your API to save updated roles
  //   fetch(`/api/departments/${deptId}/update-roles`, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ roles: updatedRoles }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("Roles updated successfully:", data);
  //       // Update the UI as needed
  //     })
  //     .catch((error) => console.error("Failed to update roles:", error));
  // };

  const handleAddDepartment = async () => {
    try {
      if (imageFile) {
        let imageUrl = "";
        const imageRef = ref(storage, `departments/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/departments/add`,
        { name: departmentName, imageUrl: imageUrl }
      );

      handleCloseModal();
      toast.success('Department added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      toast.error('Error adding department:', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageRef = ref(storage, `departmentImages/${file.name}`);
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
        console.log("Image URL:", url);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleRemoveImage = () => {
    setImageUrl(null);
  };


  const handleAddSubAdmin = (department) => {
    setSelectedDepartment(department);
    setShowAddSubAdmin(true);
  };

  const handleOpenUpdateModal = (department) => {
    setSelectedDepartment(department);
    setUpdateDepartmentName(department.name);
    setIsUpdateModalOpen(true);
  };

  const handleViewSubAdmin = async (departmentId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments/${departmentId}/subadmins`);
      setSubAdmins(response.data);
      console.log(response.data);
      setShowViewSubAdmin(true);
    } catch (error) {
      console.error('Error fetching sub-admins:', error);
    }
  };


  const handleUpdateDepartment = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/departments/${selectedDepartment._id}`,
        { name: updateDepartmentName }
      );
      setDepartments((prevDepartments) =>
        prevDepartments.map((dept) =>
          dept._id === response.data._id ? response.data : dept
        )
      );
      handleCloseUpdateModal();
    } catch (error) {
      toast.error('Error updating department:', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleDeleteDept = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/departments/${id}`);
      setDepartments(departments.filter(dept => dept._id !== id));
      toastSuccess("Department deleted successfully.");
    } catch (error) {
      toastError("Error deleting course:", error);
    }
  }

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
    setUpdateDepartmentName('');
    setSelectedDepartment(null);
  };

  const toastSuccess = (text) => toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  const toastError = (text) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  return (
    <>
      <div className='min-h-[91vh] ' style={{ backgroundImage: 'url(bg.svg)' }}>
        <ToastContainer />
        <div className="max-w-full mx-auto p-6 mt-16 md:mt-0">
          <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>DEPARTMENTS</h1>
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
              <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
                  <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
              />
            </div>
            <div>
              <button
                onClick={handleOpenModal}
                className=" border border-[#B4CAF4] rounded-[6px] px-4 py-2 flex items-center hover:bg-[#0760FF] hover:bg-opacity-25 cursor-pointer"
              >
                <span className='text-[#0760FF] hover:text-blue-800 text-[15px] leading-[17.63px] p-2 font-medium hover:font-semibold cursor-pointer'>Add Department</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3103 2.70044C12.6274 2.0173 11.6628 1.91016 10.5245 1.91016H5.46196C4.33711 1.91016 3.37282 2.0173 2.68968 2.70016C2.00654 3.38301 1.90625 4.34073 1.90625 5.4593V10.5216C1.90625 11.6667 2.00682 12.6176 2.68968 13.3007C3.37254 13.9839 4.33711 14.0907 5.48225 14.0907H10.5245C11.6628 14.0907 12.6274 13.9836 13.3103 13.3007C13.9931 12.6179 14.0937 11.6667 14.0937 10.5216V5.4793C14.0937 4.33416 13.9934 3.37673 13.3103 2.70044ZM7.99996 11.9276C7.85975 11.9274 7.72533 11.8716 7.62616 11.7725C7.52698 11.6733 7.47112 11.5389 7.47082 11.3987V8.52616H4.60511C4.31025 8.52616 4.07596 8.28501 4.07596 8.00387C4.07596 7.72273 4.31025 7.46816 4.60482 7.46816H7.47082V4.60187C7.47082 4.30073 7.70511 4.06616 7.99996 4.06616C8.30139 4.06616 8.52911 4.30044 8.52911 4.60187V7.46816H11.4017C11.6965 7.46816 11.9308 7.72244 11.9308 8.00387C11.9308 8.28501 11.6965 8.52616 11.4017 8.52616H8.52911V11.399C8.52953 11.4686 8.51612 11.5376 8.48967 11.602C8.46323 11.6663 8.42426 11.7248 8.37504 11.774C8.32582 11.8232 8.26732 11.8621 8.20293 11.8885C8.13854 11.915 8.06956 11.928 7.99996 11.9276Z" fill="#04CF31" />
                </svg>

              </button>
              {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                  <div className="bg-white p-6 rounded shadow-lg w-[50%]">
                    <h2 className="text-lg font-semibold mb-4">Add Department</h2>
                    <input
                      type="text"
                      placeholder="Department Name"
                      value={departmentName}
                      onChange={(e) => setDepartmentName(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded mb-4"
                    />
                    <div className='flex items-center'>
                      <label className="relative cursor-pointer bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-600 transition-all">
                        <span className="text-sm font-medium">Choose Image</span>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          className="absolute inset-0 opacity-0 cursor-pointer"
                        />
                      </label>

                      {imageUrl && (
                        <div className="mt-2">
                          <button
                            onClick={() => window.open(imageUrl, '_blank')}
                            className="text-blue-500 hover:text-blue-700"
                            aria-label="View Image"
                          >
                            <FiEye className="h-6 w-6 mx-2" />
                          </button>
                          <button
                            onClick={handleRemoveImage}
                            className="text-red-500 hover:text-red-700"
                            aria-label="Remove Image"
                          >
                            <FiTrash className="h-6 w-6" />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-end">
                      {
                        departmentName && imageUrl ? (
                          <button
                            onClick={handleAddDepartment}
                            className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
                          >
                            Add
                          </button>) : (
                          <button
                            className="bg-gray-300 text-white px-4 py-2 rounded mr-2"
                            disabled={true}
                          >
                            Add
                          </button>
                        )
                      }
                      <button
                        onClick={handleCloseModal}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {filteredDepartments.map((dept, index) => (
              <Card key={index}>
                <div className="min-h-[150px] flex flex-col justify-between">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="m-0 text-[19.64px] font-medium leading-[23.08px] text-[#060101]">
                      {dept.name}
                    </h3>
                    <div className="flex flex-row w-[12%] justify-between">
                      <svg
                        onClick={() => handleOpenUpdateModal(dept)}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          color: "#6B7280",
                        }}
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                      <button>
                        <FiTrash
                          className="cursor-pointer text-red-500 hover:text-red-700"
                          onClick={() => handleDeleteDept(dept._id)}
                        />
                      </button>
                    </div>
                  </div>
                  <img
                    src={dept.image}
                    alt={dept.name}
                    className="w-full h-40 my-3 object-cover rounded mb-6"
                  />

                  <div
                    className="w-full mb-2 border border-[#ECF3FF] text-center p-2"
                    style={{ boxShadow: "0px 0px 4px 0px #00000040" }}>
                    <button
                      onClick={() => {
                        setEditRoleModalOpen(true);
                        setSelectedDepartment(dept);
                      }}
                      className="hover:bg-gray-100 hover:scale-95 text-[#3EBA59] font-semibold"
                    >
                      Edit Roles
                    </button>
                  </div>
                  <div className="flex justify-between mt-[10] flex-col md:flex-row gap-4 rounded-[6.55px]">
                    <Button
                      onClick={() => handleAddSubAdmin(dept)}
                      className="bg-[#3EBA59] text-white hover:bg-green-500 hover:scale-95"
                    >
                      Add Sub-Admin
                    </Button>
                    <Button
                      onClick={() => handleViewSubAdmin(dept._id)}
                      className="border border-gray-300 hover:bg-gray-100 hover:scale-95 text-[#3EBA59] font-semibold"
                    >
                      View SubAdmin
                    </Button>
                  </div>
                </div>
              </Card>
            ))}
          </div>

          {showAddSubAdmin && (
            <AddSubAdmin
              onClose={() => setShowAddSubAdmin(false)}
              departmentId={selectedDepartment._id}
            />
          )}

          {showViewSubAdmin && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-[90%] md:w-[90%] h-5/6 overflow-auto p-4">
                <ViewSubAdminModal
                  subAdmins={subAdmins}
                  onClose={() => setShowViewSubAdmin(false)}
                />
                <button
                  onClick={() => setShowViewSubAdmin(false)}
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                >
                  &times;
                </button>
              </div>
            </div>
          )}

          {isUpdateModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded shadow-lg w-80">
                <h2 className="text-lg font-semibold mb-4">Update Department</h2>
                <input
                  type="text"
                  placeholder="Department Name"
                  value={updateDepartmentName}
                  onChange={(e) => setUpdateDepartmentName(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mb-4"
                />
                <div className="flex justify-end">
                  <button
                    onClick={handleUpdateDepartment}
                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
                  >
                    Update
                  </button>
                  <button
                    onClick={handleCloseUpdateModal}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          <EditRoleModal
            isOpen={isEditRoleModalOpen}
            onClose={() => setEditRoleModalOpen(false)}
            department={selectedDepartment}
            onSave={async (deptId, updatedRoles) => {
              try {
                const payload = { roles: updatedRoles.map((role) => String(role).trim()) };
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/api/departments/${deptId}/roles`,
                  payload,
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                console.log("Roles updated successfully:", response.data);
              } catch (error) {
                console.error("Error updating roles:", error.response?.data || error.message);
              }
            }}
          />

        </div>
      </div>
    </>
  );
}