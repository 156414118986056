// import React from 'react';

// const ViewSubAdminModal = ({ subAdmins, onClose }) => (
//   <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//     <div className="bg-white p-6 rounded shadow-lg w-96">
//       <h2 className="text-lg font-semibold mb-4">Sub-Admins</h2>
//       <div className="overflow-auto">
//         <table className="min-w-full text-left">
//           <thead>
//             <tr>
//               <th className="py-2 px-4 border-b">Name</th>
//               <th className="py-2 px-4 border-b">EMAIL</th>
//               <th className="py-2 px-4 border-b">_id</th>
//             </tr>
//           </thead>
//           <tbody>
//             {subAdmins.map((subAdmin, index) => (
//               <tr key={index}>
//                 <td className="py-2 px-4 border-b">{subAdmin.name}</td>
//                 <td className="py-2 px-4 border-b">{subAdmin.departmentName}</td>
//                 <td className="py-2 px-4 border-b">{subAdmin._id}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="flex justify-end mt-4">
//         <button onClick={onClose} className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400">
//           Close
//         </button>
//       </div>
//     </div>
//   </div>
// );

// export default ViewSubAdminModal;


// import React, { useState } from 'react';
// import DataTable from 'react-data-table-component';
// import { FiEdit, FiTrash } from 'react-icons/fi';
// import axios from 'axios';

// const ViewSubAdminModal = ({ subAdmins, onClose }) => {
//     const [search, setSearch] = useState('');
//     const [data, setData] = useState(subAdmins);

//     const handleDelete = async (subadminId) => {
//         const confirmDelete = window.confirm("Are you sure you want to delete this sub-admin?");
//         if (confirmDelete) {
//             try {
//                 await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/departments/subadmin/${subadminId}`);
//                 setData(data.filter(item => item._id !== subadminId));
//                 alert("Sub-admin deleted successfully");
//             } catch (error) {
//                 console.error("Error deleting sub-admin:", error);
//             }
//         }
//     };

//     const columns = [
//         {
//             name: 'NAME',
//             selector: row => row.name || 'N/A', 
//             sortable: true,
//         },
//         {
//             name: 'SUBADMINID',
//             selector: row => (row.adminId && row.adminId._id) ? row.adminId._id : 'N/A',
//             sortable: true,
//             width: '280px',
//         },
//         {
//             name: 'EMAIL',
//             selector: row => (row.adminId && row.adminId.email) ? row.adminId.email : 'N/A', 
//             sortable: true,
//             width: '320px',
//         },
//         {
//             name: 'PHONE',
//             selector: row => (row.adminId && row.adminId.phone) ? row.adminId.phone : 'N/A',  
//             sortable: true,
//             width: '200px',
//         },
//         {
//             name: 'DEPARTMENT',
//             selector: row => row.departmentName || 'N/A', 
//             sortable: true,
//             width: '200px',
//         },
//         {
//             name: 'ACTION',
//             cell: row => (
//                 <div className="flex space-x-2 justify-center py-3">
//                     <button className="text-blue-600 hover:text-blue-800">
//                         <FiEdit />
//                     </button>
//                     <button onClick={() => handleDelete(row._id)} className="text-red-600 hover:text-red-800">
//                         <FiTrash />
//                     </button>
//                 </div>
//             ),
//         },
//     ];
    
//     const filteredData = subAdmins.filter(item =>
//         item.name.toLowerCase().includes(search.toLowerCase())
//     );

    
//     const customStyles = {
//         header: {
//             style: {
//                 minHeight: '56px',
//                 backgroundColor: '#f1f3f5',
//                 color: '#343a40',
//                 fontSize: '18px',
//                 fontWeight: 'bold',
//             },
//         },
//         headRow: {
//             style: {
//                 backgroundColor: '#f1f3f5',
//                 borderBottomWidth: '2px',
//                 borderBottomColor: '#dee2e6',
//                 borderBottomStyle: 'solid',
//             },
//         },
//         headCells: {
//             style: {
//                 color: '#343a40',
//                 fontWeight: 'bold',
//                 fontSize: '18px',
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//             },
//         },
//         rows: {
//             style: {
//                 minHeight: '48px',
//                 '&:not(:last-of-type)': {
//                     borderBottomWidth: '1px',
//                     borderBottomColor: '#dee2e6',
//                     borderBottomStyle: 'solid',
//                 },
//             },
//         },
//         cells: {
//             style: {
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//                 fontSize: '16px',
//             },
//         },
//         pagination: {
//             style: {
//                 borderTopWidth: '1px',
//                 borderTopColor: '#dee2e6',
//                 borderTopStyle: 'solid',
//                 backgroundColor: '#f1f3f5',
//                 minHeight: 'auto'
//             },
//         },
//     };

//     const laptopPaginationStyles = `
//     @media (min-width: 1024px) {
//         .rdt_Pagination {
//             min-height: 56px;
//         }
//     }
// `;

//     return (
//         <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto' }}>
//             <style>{laptopPaginationStyles}</style>
//             <div className="flex justify-end mb-5">
//                 <input
//                     type="text"
//                     placeholder="Search..."
//                     value={search}
//                     onChange={e => setSearch(e.target.value)}
//                     className="w-3/4 md:w-1/3 pl-8 pr-3 py-4 text-sm text-gray-700 bg-slate-100 rounded-full focus:outline-none"
//                 />
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredData}
//                 pagination
//                 highlightOnHover
//                 pointerOnHover
//                 dense
//                 customStyles={customStyles}
//             />
//             <button onClick={onClose} className="mt-4 bg-gray-300 px-4 py-2 rounded">Close</button>
//         </div>
//     );
// };

// export default ViewSubAdminModal;


import React, { useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FiEdit, FiTrash } from 'react-icons/fi';

const ViewSubAdminModal = ({ subAdmins, onClose }) => {
    const [search, setSearch] = useState('');
    const [data, setData] = useState(subAdmins);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleDelete = async (subadminId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this sub-admin?");
        if (confirmDelete) {
            try {
                // Access the nested _id within adminId for the delete request
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/departments/subadmin/${subadminId}`);
                setData(data.filter(item => item.adminId._id !== subadminId));
                alert("Sub-admin deleted successfully");
            } catch (error) {
                console.error("Error deleting sub-admin:", error);
            }
        }
    };
    

    const openEditModal = (subAdmin) => {
        setEditData(subAdmin);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditData(null);
    };

    const handleEditChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const handleEditSubmit = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/api/departments/subadmin/${editData.adminId._id}/edit`, editData);
            setData(data.map(item => item._id === editData._id ? editData : item));
            console.log(`${process.env.REACT_APP_BASE_URL}/api/departments/subadmin/${editData.adminId._id}`)
            closeEditModal();
            alert("Sub-admin updated successfully");
        } catch (error) {
            console.error("Error updating sub-admin:", error);
        }
    };

    const columns = [
        { name: 'NAME', selector: row => row.name || 'N/A', sortable: true, width: '150px' },
        { name: 'SUBADMINID', selector: row => (row.adminId && row.adminId._id) ? row.adminId._id : 'N/A', sortable: true, width: '280px' },
        { name: 'EMAIL', selector: row => (row.adminId && row.adminId.email) ? row.adminId.email : 'N/A', sortable: true, width: '320px' },
        { name: 'PHONE', selector: row => (row.adminId && row.adminId.phone) ? row.adminId.phone : 'N/A', sortable: true, width: '200px' },
        { name: 'DEPARTMENT', selector: row => row.departmentName || 'N/A', sortable: true, width: '200px' },
        {
            name: 'ACTION',
            cell: row => (
                <div className="flex space-x-2 justify-center py-5">
                    <button onClick={() => openEditModal(row)} className="text-blue-600 hover:text-blue-800">
                        <FiEdit />
                    </button>
                    <button onClick={() => handleDelete(row.adminId._id)} className="text-red-600 hover:text-red-800">
                        <FiTrash />
                    </button>
                </div>
            ),
        },
    ];
    
    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    const customStyles = {
        header: { style: { minHeight: '56px', backgroundColor: '#f1f3f5', color: '#343a40', fontSize: '18px', fontWeight: 'bold' } },
        headRow: { style: { backgroundColor: '#f1f3f5', borderBottomWidth: '2px', borderBottomColor: '#dee2e6', borderBottomStyle: 'solid' } },
        headCells: { style: { color: '#343a40', fontWeight: 'bold', fontSize: '18px', paddingLeft: '16px', paddingRight: '16px' } },
        rows: { style: { minHeight: '48px', '&:not(:last-of-type)': { borderBottomWidth: '1px', borderBottomColor: '#dee2e6', borderBottomStyle: 'solid' } } },
        cells: { style: { paddingLeft: '16px', paddingRight: '16px', fontSize: '16px' } },
        pagination: { style: { borderTopWidth: '1px', borderTopColor: '#dee2e6', borderTopStyle: 'solid', backgroundColor: '#f1f3f5', minHeight: 'auto' } },
    };

    return (
        <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto' }}>
            <div className="flex justify-end mb-5">
                <input
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className="w-3/4 md:w-1/3 pl-8 pr-3 py-4 text-sm text-gray-700 bg-slate-100 rounded-full focus:outline-none"
                />
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                pointerOnHover
                dense
                customStyles={customStyles}
            />

            {isEditModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg w-96">
                        <h2 className="text-lg font-semibold mb-4">Edit Sub-Admin</h2>
                        <input
                            name="name"
                            value={editData.name}
                            onChange={handleEditChange}
                            placeholder="Name"
                            className="border rounded p-2 mb-2 w-full"
                        />
                        <input
                            name="email"
                            value={editData.adminId.email}
                            onChange={handleEditChange}
                            placeholder="Email"
                            className="border rounded p-2 mb-2 w-full"
                        />
                        <input
                            name="phone"
                            value={editData.adminId.phone}
                            onChange={handleEditChange}
                            placeholder="Phone"
                            className="border rounded p-2 mb-2 w-full"
                        />
                        <div className="flex justify-end space-x-2 mt-4">
                            <button onClick={handleEditSubmit} className="bg-blue-500 text-white rounded px-4 py-2">Save</button>
                            <button onClick={closeEditModal} className="bg-gray-300 rounded px-4 py-2">Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            <button onClick={onClose} className="mt-4 bg-gray-300 px-4 py-2 rounded">Close</button>
        </div>
    );
};

export default ViewSubAdminModal;
