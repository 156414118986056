import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleLeft } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import backgroundImage from '../Assets/wallapaper.jpeg'
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from './firebaseConfig'
import SearchUser from './SearchUser';

const MemberChat = () => {
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false)
  const [participants, setParticipants] = useState([])
  const [messages, setMessages] = useState([])
  const [inputMessage, setInputMessage] = useState()
  const [imageUrl, setImageUrl] = useState(null);
  const [openParticipant, setOpenParticipant] = useState(false);
  const fileInputRef = useRef()
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  const handleUploadImageVideoOpen = () => {
    setOpenImageVideoUpload(preve => !preve)
  }

  const sendMessageToAPI = async (message) => {
    try {
      await axios.post(
        "https://webai-forex-backend-ashy.vercel.app/api/admin/group/67290e562faba7bce90089d9/message",
        {
          adminEmail: "admin123@gmail.com",
          message: message.type === 'text' ? message.payload : message.payload.src
        }
      )
    } catch (error) {
      console.error("Error sending message to API:", error)
    }
  }

  const sanitizeMessage = (message) => {
    if (typeof message !== 'string') return ''
    let sanitized = message.replace(/<[^>]+>/g, '')
    sanitized = sanitized.replace(/&nbsp;/g, ' ')
    sanitized = sanitized.replace(/&lt;/g, '<')
    sanitized = sanitized.replace(/&gt;/g, '>')
    sanitized = sanitized.replace(/&amp;/g, '&')
    sanitized = sanitized.replace(/&quot;/g, '"')
    sanitized = sanitized.replace(/&#39;/g, "'")
    sanitized = sanitized.replace(/\s+/g, ' ')
    sanitized = sanitized.trim()

    return sanitized
  }

  const handleAttachClick = () => {
    fileInputRef.current.click()
  }
  const uploadImage = async (file) => {
    const imageName = `chat-images/${Date.now()}-${file.name}`
    const imageRef = ref(storage, imageName)
    await uploadBytes(imageRef, file)
    return await getDownloadURL(imageRef)
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      try {
        const imageUrl = await uploadImage(file)
        const newMessage = {
          direction: 'outgoing',
          type: 'image',
          payload: {
            src: imageUrl,
            alt: file.name,
            width: 300,
            height: 200,
          },
          position: 'single',
          sender: 'Admin',
          sentTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
          imageName: file.name
        }
        setOpenImageVideoUpload(false)
        setMessages((prevMessages) => [...prevMessages, newMessage])
        await sendMessageToAPI(newMessage)
      } catch (error) {
        console.error("Error uploading image:", error)
      }
    }
  }

  const handleSend = async (e) => {
    e.preventDefault();
    const sanitizedMessage = sanitizeMessage(inputMessage)
    if (!sanitizedMessage && !imageUrl) return;
    const newMessage = {
      direction: 'outgoing',
      type: 'text',
      payload: sanitizedMessage,
      position: 'single',
      sender: 'Admin',
      sentTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
    }
    setMessages((prevMessages) => [...prevMessages, newMessage])
    await sendMessageToAPI(newMessage)
    setInputMessage('')
    setImageUrl(null);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://webai-forex-backend-ashy.vercel.app/api/chat/group/67290e562faba7bce90089d9")
        const apiMessages = response.data.messages.map(msg => ({
          type: msg.message && (msg.message.startsWith('https://firebasestorage.googleapis.com') || /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(msg.message)) ? 'image' : 'text',
          direction: 'outgoing',
          payload: msg.message || '',
          position: 'single',
          sender: msg.senderUsername,
          sentTime: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
        }))
        setMessages(apiMessages)
        setParticipants(response.data.participants)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    fetchData()
  }, [])


  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className='bg-no-repeat bg-cover'>
      <header className='sticky top-0 mt-18 h-16 bg-white flex justify-between items-center px-4'>
        <div className='flex items-center gap-4'>
          <Link to={"/"} className='lg:hidden'>
            <FaAngleLeft size={25} />
          </Link>
          <div>
            <h3 className='font-semibold text-lg my-0 text-ellipsis line-clamp-1'>Admin</h3>
          </div>
        </div>

        <div>
          <button className='cursor-pointer hover:text-primary' onClick={() => setOpenParticipant(preve => !preve)}>
            <HiDotsVertical />
          </button>
        </div>
      </header>
      <section className='h-[calc(100vh-128px)] overflow-x-hidden overflow-y-scroll scrollbar relative bg-slate-200 bg-opacity-50'>
        <div className='flex justify-end py-2 mx-2'>
          <div className='flex flex-col gap-3 w-full max-w-2xl'>
            {messages.map((msg, index) => (
              msg.type === 'text' ? (
                <div key={`message-${index}`} className="p-2 py-1 rounded-lg w-fit max-w-full md:max-w-md lg:max-w-lg ml-auto bg-teal-100">
                  <p className='px-3 text-lg'>{msg.payload}</p>
                  <p className='text-xs mt-1 ml-auto w-fit'>{msg.sentTime}</p>
                </div>
              ) : (
                <div key={`message-${index}`} className="p-1 py-1 rounded-lg w-fit max-w-full md:max-w-md lg:max-w-lg ml-auto bg-teal-100">
                  <div className='w-full relative max-w-[300px] md:max-w-lg'>
                    <img
                      src={typeof msg.payload === 'string' ? msg.payload : msg.payload.src}
                      className='w-full h-full object-scale-down'
                      alt="sent media"
                    />
                  </div>
                  <p className='text-xs mt-1 ml-auto w-fit'>{msg.sentTime}</p>
                </div>
              )
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>
        {imageUrl && (
          <div className='w-full h-full sticky bottom-0 bg-slate-700 bg-opacity-30 flex justify-center items-center rounded overflow-hidden'>
            <div className='w-fit p-2 absolute top-0 right-0 cursor-pointer hover:text-red-600' onClick={handleAttachClick}>
              <IoClose size={30} />
            </div>
            <div className='bg-white p-3'>
              <img
                src={imageUrl}
                alt='upload preview'
                className='aspect-square w-full h-full max-w-sm m-2 object-scale-down'
              />
            </div>
          </div>
        )}
      </section>

      {/**send message */}
      <section className='h-16 bg-white flex items-center px-4'>
        <div className='relative'>
          <button
            onClick={handleUploadImageVideoOpen}
            className='flex justify-center items-center w-11 h-11 rounded-full hover:bg-[#05adb5] hover:text-white'
          >
            <FaPlus size={20} />
          </button>
          {openImageVideoUpload && (
            <div className='bg-white shadow rounded absolute bottom-14 w-36 p-2'>
              <form>
                <label htmlFor='uploadImage' className='flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer'>
                  <div className='text-primary'>
                    <FaImage size={18} />
                  </div>
                  <p>Image</p>
                </label>
                <input
                  type='file'
                  id='uploadImage'
                  onChange={handleFileChange}
                  className='hidden'
                  accept="image/*"
                />
              </form>
            </div>
          )}
        </div>

        {/**input box */}
        <form className='h-full w-full flex gap-2'
        >
          <input
            type='text'
            placeholder='Type here message...'
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            className='py-1 px-4 outline-none w-full h-full'
          />
          <button className='text-primary hover:text-secondary' onClick={handleSend}>
            <IoMdSend size={28} />
          </button>
        </form>

      </section>

      {
        openParticipant && (
          <SearchUser onClose={() => setOpenParticipant(false)} />
        )
      }

    </div>
  )
}

export default MemberChat
