import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../Contexts/ContextProvider";
import { links as defaultLinks } from "../Data/dummy";
import CharacterImg from '../Assets/character_avtar.png';
import Logo from '../Assets/Logo.svg';
import { HiOutlineLogout } from "react-icons/hi";


const Sidebar = ({ handleLogout }) => {
  const { activeMenu, setActiveMenu, screenSize, currentColor, role } =
    useStateContext();

  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-3xl text-white text-md m-2 bg-[#BBB7FF40]";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-3xl text-md text-white hover:bg-[#BBB7FF40] m-2";


  const filteredLinks = defaultLinks.map((section) => ({
    ...section,
    links: section.links.filter((link) => {
      if (role === "admin") return true; // Admins see all links
      // return !["subadmin", "suspendedaccount", "verification"].includes(link.url); // Others see limited links
      return ![].includes(link.url);
    }),
  }));

  return (
    <div className={`rounded-b-3xl h-[calc(100vh-24px)] md:overflow-hidden overflow-hidden pb-10 'w-72' transition-all duration-500`}>
      {activeMenu && (
        <div className="bg-[#0D0148] rounded-b-3xl h-[calc(100vh-24px)] flex flex-col justify-between py-4 px-2 overflow-y-auto">
          <div>
            <div className="flex justify-between items-center pl-2">
              <Link to="/" onClick={handleCloseSidebar} className="items-center gap-3 flex text-xl font-semibold tracking-tight text-white">
                <img src="mainlogo.svg" alt="Forex-Tradie" className='w-[90%]' />
                {/* <span>Forexyy</span> */}
              </Link>
            </div>
            <div className="mt-10">
              {filteredLinks.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  {section.links.map((link) => (
                    <NavLink
                      to={`/${link.url}`}
                      key={link.name}
                      onClick={handleCloseSidebar}
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-3xl text-white text-md m-2 bg-[#BBB7FF40]"
                          : "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-3xl text-md text-white hover:bg-[#BBB7FF40] m-2"
                      }
                    >
                      {link.icon}
                      <span className={`${activeMenu ? 'block' : 'hidden'} capitalize`}>{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="bottom-0 w-full p-4 text-center">
            <div className="mt-auto border-t-1 border-[#E2E8F01A]">
              <div className="flex items-center gap-3 rounded-xl py-5" onClick={() => handleLogout()}>
                <div className="flex flex-col items-start">
                  <span className="text-lg font-semibold text-red-500">Logout</span>
                </div>
                <button className="ml-auto text-white">
                  <HiOutlineLogout size={25} color={'#FF0000'} />

                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
