import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); 

const EditRoleModal = ({ isOpen, onClose, department, onSave }) => {
  const [selectedRoles, setSelectedRoles] = useState(department?.roles || []);

  const roles = [
    "Signal",
    "Department",
    "Analysis",
    "Courses",
    "Sub Admin",
    "Members",
    "Verification",
    "Settings",
  ];

  const handleRoleToggle = (role) => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  const handleSave = () => {
    const validRoles = selectedRoles
      .filter((role) => typeof role === "string" && role.trim().length > 0) 
      .map((role) => role.trim());
  
    onSave(department._id, validRoles);
    onClose();
  };
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="w-[95%] max-w-md bg-white rounded-lg shadow-xl p-6 mx-auto mt-20"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <h2 className="text-lg font-semibold mb-4 text-gray-800">
        Edit Roles for {department?.name}
      </h2>
      <div className="space-y-3">
        {roles.map((role) => (
          <div key={role} className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={selectedRoles.includes(role)}
              onChange={() => handleRoleToggle(role)}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-300"
            />
            <label className="text-gray-700">{role}</label>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-end space-x-4">
        <button
          onClick={onClose}
          className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none"
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default EditRoleModal;
