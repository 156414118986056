import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bounce, toast, ToastContainer } from 'react-toastify';

const Input = ({ label, type, placeholder, value, onChange, name, icon, onIconClick }) => (
  <div className="mb-4 relative">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <div className="relative">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      />
      {icon && (
        <span
          className="absolute inset-y-0 right-3 flex items-center justify-center cursor-pointer"
          onClick={onIconClick}
        >
          {icon}
        </span>
      )}
    </div>
  </div>
);

export default function AddSubAdmin({ departmentId, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    subAdminId: '',
    email: '',
    phone: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/departments/${departmentId}/add-subadmin`,
        formData
      );
      toastSuccess("Sub-admin added successfully");
      onClose();
    } catch (error) {
      toastError('Error adding sub-admin:', error);
    }
  };

  const toastSuccess = (text) => toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
  const toastError = (text) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments/${departmentId}`);
        setRoles(response.data.roles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, [departmentId]);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-4xl">
        <h2 className="text-2xl font-bold mb-6">Add Sub-admin</h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">


          <Input
            label="Name"
            type="text"
            placeholder="Enter name"
            value={formData.name}
            onChange={handleChange}
            name="name"
            className="col-span-1 lg:col-span-1"
          />


          <Input
            label="Subadmin ID"
            type="text"
            placeholder="Enter Subadmin ID"
            value={formData.subAdminId}
            onChange={handleChange}
            name="subAdminId"
            className="col-span-1 lg:col-span-1"
          />


          <Input
            label="Email address"
            type="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={handleChange}
            name="email"
            className="col-span-1 sm:col-span-2 lg:col-span-3"
          />


          <Input
            label="Phone number"
            type="tel"
            placeholder="Enter phone number"
            value={formData.phone}
            onChange={handleChange}
            name="phone"
            className="col-span-1 lg:col-span-1"
          />


          <Input
            label="Password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            value={formData.password}
            onChange={handleChange}
            name="password"
            icon={
              showPassword ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#000000" fill="none">
                  <path d="M21.544 11.045C21.848 11.4713 22 11.6845 22 12C22 12.3155 21.848 12.5287 21.544 12.955C20.1779 14.8706 16.6892 19 12 19C7.31078 19 3.8221 14.8706 2.45604 12.955C2.15201 12.5287 2 12.3155 2 12C2 11.6845 2.15201 11.4713 2.45604 11.045C3.8221 9.12944 7.31078 5 12 5C16.6892 5 20.1779 9.12944 21.544 11.045Z" stroke="currentColor" stroke-width="1.5" />
                  <path d="M15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12Z" stroke="currentColor" stroke-width="1.5" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#000000" fill="none">
                  <path d="M19.439 15.439C20.3636 14.5212 21.0775 13.6091 21.544 12.955C21.848 12.5287 22 12.3155 22 12C22 11.6845 21.848 11.4713 21.544 11.045C20.1779 9.12944 16.6892 5 12 5C11.0922 5 10.2294 5.15476 9.41827 5.41827M6.74742 6.74742C4.73118 8.1072 3.24215 9.94266 2.45604 11.045C2.15201 11.4713 2 11.6845 2 12C2 12.3155 2.15201 12.5287 2.45604 12.955C3.8221 14.8706 7.31078 19 12 19C13.9908 19 15.7651 18.2557 17.2526 17.2526" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.85786 10C9.32783 10.53 9 11.2623 9 12.0711C9 13.6887 10.3113 15 11.9289 15C12.7377 15 13.47 14.6722 14 14.1421" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M3 3L21 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              )
            }
            onIconClick={togglePasswordVisibility}
            className="col-span-1 sm:col-span-2 lg:col-span-3"
          />

          <div className="col-span-1 sm:col-span-2 lg:col-span-3 flex justify-between space-x-4 mt-6">
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="md:px-20 px-4 py-2 bg-[#3EBA59] text-white rounded-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Add Sub-Admin
            </button>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </form>

      </div>
    </div>
  );
}
