import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-modal/styles.css";
import Slider from "react-slick";
import AddAnalysis from "./AddAnalysis";
import { Bounce, toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Analysis = () => {
  const [analysisData, setAnalysisData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddAnalysisModalOpen, setIsAddAnalysisModalOpen] = useState(false);
  const [search, setSearch] = useState("");
const [filteredData, setFilteredData] = useState([]);

  const fetchAnalysisData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/analysis`
      );
      if (Array.isArray(response.data)) {
        setAnalysisData(response.data);
      } else {
        setAnalysisData([]);
        toastError("Unexpected data format received");
      }
    } catch (error) {
      console.error("Error fetching analysis data:", error);
      toastError("Failed to fetch analysis data");
    }
  };

  useEffect(() => {
    fetchAnalysisData();
  }, []);

  useEffect(() => {
    const filtered = analysisData.filter(item =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(filtered);
  }, [search, analysisData]);
  

  const handleReadMore = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const openAddAnalysisModal = () => {
    setIsAddAnalysisModalOpen(true);
    setSelectedItem(null);
  };

  const closeAddAnalysisModal = () => {
    setIsAddAnalysisModalOpen(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsAddAnalysisModalOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this analysis?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/analysis/${id}`
        );
        toastSuccess(response.data.message);
        fetchAnalysisData();
      } catch (error) {
        toastError("Failed to delete analysis");
        console.error("Error deleting analysis:", error);
      }
    }
  };

  if (error) {
    return <div className="text-red-600">Error: {error}</div>;
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  const toastSuccess = (text) => toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  const toastError = (text) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  return (
    <> 
    <div className='min-h-[91vh] relative' style={{ backgroundImage: 'url(bg.svg)'}}>
    <div className="p-5 md:px-8 md:mt-4 mt-16 mb-16 lg:mb-0 absolute w-full">
      <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>ANALYSIS</h1>
      <div className="flex justify-between flex-col md:flex-row gap-3 md:gap-0 w-44 md:w-auto">
        <div>
          <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
            <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
                <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
            />
          </div>
        </div>
        <button
          onClick={openAddAnalysisModal}
          className=" border border-[#B4CAF4] rounded-[15px] md:rounded-[6px] px-4 py-2 flex items-center hover:bg-[#0760FF] hover:bg-opacity-25 cursor-pointer mb-5"
        >
          <span className='text-[#0760FF] hover:text-blue-800 text-[15px] leading-[17.63px] p-2 font-medium hover:font-semibold cursor-pointer'>Add Analysis</span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3103 2.70044C12.6274 2.0173 11.6628 1.91016 10.5245 1.91016H5.46196C4.33711 1.91016 3.37282 2.0173 2.68968 2.70016C2.00654 3.38301 1.90625 4.34073 1.90625 5.4593V10.5216C1.90625 11.6667 2.00682 12.6176 2.68968 13.3007C3.37254 13.9839 4.33711 14.0907 5.48225 14.0907H10.5245C11.6628 14.0907 12.6274 13.9836 13.3103 13.3007C13.9931 12.6179 14.0937 11.6667 14.0937 10.5216V5.4793C14.0937 4.33416 13.9934 3.37673 13.3103 2.70044ZM7.99996 11.9276C7.85975 11.9274 7.72533 11.8716 7.62616 11.7725C7.52698 11.6733 7.47112 11.5389 7.47082 11.3987V8.52616H4.60511C4.31025 8.52616 4.07596 8.28501 4.07596 8.00387C4.07596 7.72273 4.31025 7.46816 4.60482 7.46816H7.47082V4.60187C7.47082 4.30073 7.70511 4.06616 7.99996 4.06616C8.30139 4.06616 8.52911 4.30044 8.52911 4.60187V7.46816H11.4017C11.6965 7.46816 11.9308 7.72244 11.9308 8.00387C11.9308 8.28501 11.6965 8.52616 11.4017 8.52616H8.52911V11.399C8.52953 11.4686 8.51612 11.5376 8.48967 11.602C8.46323 11.6663 8.42426 11.7248 8.37504 11.774C8.32582 11.8232 8.26732 11.8621 8.20293 11.8885C8.13854 11.915 8.06956 11.928 7.99996 11.9276Z" fill="#04CF31" />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {filteredData.map((item) => (
          <div
            key={item._id}
            className="bg-[#EBEBEB] p-5 rounded-lg shadow flex flex-wrap md:flex-nowrap gap-4 hover:shadow-md transition-shadow relative"
          >
            <img
              src={item.images[0]}
              alt={item.title}
              className="w-full md:max-w-[216px] h-44 object-cover rounded-md"
            />
            <div>
              <h3 className="text-base font-display font-semibold text-gray-800 mb-3">
                {item.title}
              </h3>
              <p className="text-sm text-gray-600 font-display mb-6">
                {item.description.slice(0, 85)}...
              </p>
              <button
                onClick={() => handleReadMore(item)}
                className="text-purple-600 font-display text-sm transition duration-200 ease-in-out transform hover:text-purple-800 hover:scale-105 hover:underline"
              >
                Read More
              </button>
            </div>
            <div className="absolute bottom-4 right-4 flex space-x-2">
              <button onClick={() => handleEdit(item)} aria-label="Edit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 28 28"
                  fill="currentColor"
                  className="w-7 h-7 text-blue-600 hover:text-blue-800"
                >
                  <path d="M5 20h14v2H5zM17.7 2.3c.4-.4 1-.4 1.4 0l2.6 2.6c.4.4.4 1 0 1.4l-13 13-4.2.8.8-4.2 13-13z" />
                </svg>
              </button>
              <button onClick={() => handleDelete(item._id)} aria-label="Delete">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 28 28"
                  fill="currentColor"
                  className="w-7 h-7 text-red-600 hover:text-red-800"
                >
                  <path d="M3 6h18v2H3zm3 3h12v12H6z" />
                </svg>
              </button>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </div>
          </div>
        ))}
      </div>
      {selectedItem && (
        <Modal open={isModalOpen} onClose={closeModal} center>
          <div className="p-5 md:p-6 lg:p-8 max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl mx-auto bg-white rounded-xl overflow-hidden">
            <Slider {...sliderSettings} className="mt-2">
              {selectedItem.images.map((img, index) => (
                <div key={index}>
                  <img
                    src={img}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-48 sm:h-56 md:h-64 object-cover rounded-md"
                  />
                </div>
              ))}
            </Slider>
            <h2 className="text-base sm:text-lg md:text-xl font-semibold mt-6">
              {selectedItem.title}
            </h2>
            <p className="text-gray-700 mt-4 max-h-44 overflow-y-auto pr-2 text-sm sm:text-base">
              {selectedItem.description}
            </p>
          </div>
        </Modal>
      )}
      <Modal
        open={isAddAnalysisModalOpen}
        onClose={closeAddAnalysisModal}
        center
        styles={{
          modal: {
            width: '80%',
            maxWidth: '80%',
          },
        }}
      >
        <AddAnalysis
          selectedItem={selectedItem}
          fetchAnalysisData={fetchAnalysisData}
          closeModal={closeAddAnalysisModal}
        />
      </Modal>
    </div>
    </div>
    </>
  );
};

export default Analysis;
