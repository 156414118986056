// import React, { useState } from 'react';
// import DataTable from 'react-data-table-component';
// import './Subadmin.css';

// const Table1 = () => {
//   const [search, setSearch] = useState('');
//   const [statusFilter, setStatusFilter] = useState('All');

//   const columns = [
//     {
//       name: 'TRADER ID',
//       selector: row => row.traderId,
//       sortable: true,
//     },
//     {
//       name: 'NAME',
//       selector: row => row.name,
//       sortable: true,
//     },
//     {
//       name: 'EMAIL',
//       selector: row => row.email,
//       sortable: true,
//       grow: 0,
//       width: '300px',
//     },
//     {
//       name: 'STATUS',
//       selector: row => row.status,
//       cell: row => (
//         <span
//           className={
//             row.status === 'Pending'
//               ? 'text-orange-500'
//               : row.status === 'Approved'
//                 ? 'text-green-500'
//                 : 'text-red-500'
//           }
//         >
//           {row.status}
//         </span>
//       ),
//       sortable: true,
//     },
//     {
//       name: 'ACTION',
//       cell: row => (
//         <div className="flex space-x-2 justify-center md:my-2 my-1">
//           <button
//             className="px-0 md:px-2 w-32 py-1 bg-green-500 text-white rounded hover:bg-green-600"
//             onClick={() => handleApprove(row.traderId)}
//           >
//             Approve
//           </button>
//           <button
//             className="px-0 md:px-2 w-28 py-1 bg-red-500 text-white rounded hover:bg-red-600"
//             onClick={() => handleDeny(row.traderId)}
//           >
//             Deny
//           </button>
//         </div>
//       ),
//       width: '280px',
//     },
//   ];

//   const data = [
//     {
//       traderId: "Trader123",
//       name: "Ayush Prashant",
//       email: "ayushprashant12345@gmail.com",
//       status: "Pending"
//     },
//     {
//       traderId: "Trader456",
//       name: "John Doe",
//       email: "johndoe@example.com",
//       status: "Approved"
//     },
//     {
//       traderId: "Trader789",
//       name: "Jane Smith",
//       email: "janesmith@example.com",
//       status: "Denied"
//     }
//   ];

//   const handleApprove = (traderId) => {
//     console.log(`Approved trader with ID: ${traderId}`);
//   };

//   const handleDeny = (traderId) => {
//     console.log(`Denied trader with ID: ${traderId}`);
//   };

//   const filteredData = data.filter(item =>
//     item.name.toLowerCase().includes(search.toLowerCase()) &&
//     (statusFilter === 'All' || item.status === statusFilter)
//   );

//   const customStyles = {
//     header: {
//       style: {
//         minHeight: '56px',
//         backgroundColor: '#f1f3f5',
//         color: '#343a40',
//         fontSize: '18px',
//         fontWeight: 'bold',
//       },
//     },
//     headRow: {
//       style: {
//         backgroundColor: '#f1f3f5',
//         borderBottomWidth: '2px',
//         borderBottomColor: '#dee2e6',
//         borderBottomStyle: 'solid',
//       },
//     },
//     headCells: {
//       style: {
//         color: '#343a40',
//         fontWeight: 'bold',
//         fontSize: '18px',
//         paddingLeft: '16px',
//         paddingRight: '16px',
//       },
//     },
//     rows: {
//       style: {
//         minHeight: '48px',
//         '&:not(:last-of-type)': {
//           borderBottomWidth: '1px',
//           borderBottomColor: '#dee2e6',
//           borderBottomStyle: 'solid',
//         },
//       },
//     },
//     cells: {
//       style: {
//         paddingLeft: '16px',
//         paddingRight: '16px',
//         fontSize: '16px',
//       },
//     },
//     pagination: {
//       style: {
//         borderTopWidth: '1px',
//         borderTopColor: '#dee2e6',
//         borderTopStyle: 'solid',
//         backgroundColor: '#f1f3f5',
//       },
//     },
//   };

//   return (
//     <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', }}>
//       <h1 className='font-bold text-[24px] leading-[24px] text-[#23272E] mt-16 md:mt-0'>Forexyy User-Approval</h1>
//       <div className="flex flex-col md:flex-row justify-between mb-5 gap-3 mt-8"> 
//         <div className="bg-slate-100 hover:bg-white border border-gray-300 rounded-full px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 w-3/4 md:w-auto">
//         <select
//           value={statusFilter}
//           onChange={e => setStatusFilter(e.target.value)}
//           className="bg-slate-100 hover:bg-white rounded-lg py-3 px-5 md:px-1 text-sm text-gray-700 focus:outline-none">
//           <option value="All">Filter by date range</option>
//           <option value="Pending">Pending</option>
//           <option value="Approved">Approved</option>
//           <option value="Denied">Denied</option>
//         </select>
//         </div>

//         <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//           <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//             <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//               <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//               <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//             </svg>
//           </div>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={search}
//             onChange={e => setSearch(e.target.value)}
//             className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//           />
//         </div>

//       </div>

//       <DataTable
//         columns={columns}
//         data={filteredData}
//         pagination
//         highlightOnHover
//         pointerOnHover
//         dense
//         customStyles={customStyles}
//       />
//     </div>
//   );
// };

// export default Table1;


// import React, { useState } from 'react';
// import axios from 'axios';
// import DataTable from 'react-data-table-component';
// import './Subadmin.css';

// const Table1 = () => {
//   const [search, setSearch] = useState('');
//   const [statusFilter, setStatusFilter] = useState('All');

//   const columns = [
//     {
//       name: 'TRADER ID',
//       selector: row => row.traderId,
//       sortable: true,
//     },
//     {
//       name: 'NAME',
//       selector: row => row.username,
//       sortable: true,
//     },
//     {
//       name: 'EMAIL',
//       selector: row => row.email,
//       sortable: true,
//       grow: 0,
//       width: '300px',
//     },
//     {
//       name: 'STATUS',
//       selector: row => row.status,
//       cell: row => (
//         <span
//           className={
//             row.status === 'Pending'
//               ? 'text-orange-500'
//               : row.status === 'Approved'
//                 ? 'text-green-500'
//                 : 'text-red-500'
//           }
//         >
//           {row.status}
//         </span>
//       ),
//       sortable: true,
//     },
//     {
//       name: 'ACTION',
//       cell: row => (
//         <div className="flex space-x-2 justify-center md:my-2 my-1">
//           <button
//             className="px-0 md:px-2 w-32 py-1 bg-green-500 text-white rounded hover:bg-green-600"
//             onClick={() => handleApprove(row._id["$oid"])}
//           >
//             Approve
//           </button>
//           <button
//             className="px-0 md:px-2 w-28 py-1 bg-red-500 text-white rounded hover:bg-red-600"
//             onClick={() => handleDeny(row._id["$oid"])}
//           >
//             Deny
//           </button>
//         </div>
//       ),
//       width: '280px',
//     }
//   ];

//   const data = [
//     {
//       "_id": {
//         "$oid": "67251763131cb83b4436d066"
//       },
//       "email": "tyagihimanshi2024@gmail.com",
//       "traderId": "trader_001",
//       "username": "himan6",
//       "password": "$2b$10$hpHpSEslena7.d52tOXqM.BKl4t4oH3ZTVnGu../XjhZvOwYW4/G2",
//       "role": "user",
//       "otp": "253446",
//       "createdAt": {
//         "$date": "2024-11-01T18:01:07.548Z"
//       },
//       "updatedAt": {
//         "$date": "2024-11-06T13:56:19.116Z"
//       },
//       "__v": 0,
//       "verifiedByAdmin": true
//     },
//     {
//       "_id": {
//         "$oid": "67271d205cb5392ea8101888"
//       },
//       "email": "bhaveshjoshi53903@gmail.com",
//       "traderId": "Ksbsbek",
//       "username": "Bjoshi",
//       "password": "$2b$10$wdXHH3Pd4I4cfxuqL4FYIOwXwi0AvPMWSYbwc45WGnF5AOf8cwxIu",
//       "role": "user",
//       "createdAt": {
//         "$date": "2024-11-03T06:50:08.136Z"
//       },
//       "updatedAt": {
//         "$date": "2024-11-06T14:12:48.289Z"
//       },
//       "__v": 0,
//       "verifiedByAdmin": true
//     },
//     {
//       "_id": {
//         "$oid": "672930b03cc646d879dd5b03"
//       },
//       "email": "testuser@example.com",
//       "traderId": "TRADER12783",
//       "username": "testuser",
//       "password": "$2b$10$741HhEeBGLk6iGsPPd1I9.OoF84dzv0RvqGOLZSxiPIAcEAO3xnMi",
//       "role": "user",
//       "isOtpVerified": true,
//       "status": "active",
//       "createdAt": {
//         "$date": "2024-11-04T20:38:08.894Z"
//       },
//       "updatedAt": {
//         "$date": "2024-11-04T20:38:50.922Z"
//       },
//       "__v": 0
//     },
//     {
//       "_id": {
//         "$oid": "672a4b9a20f4c3b5f04b5bdd"
//       },
//       "email": "janirudraksh228@gmail.com",
//       "traderId": "234324",
//       "username": "Test_Rudra",
//       "password": "$2b$10$h.ffDmzH5IkvM4oZn9TIWOQG9JcI7G.2AgFfzWcDEs2AKBqt5FKRW",
//       "role": "user",
//       "isOtpVerified": true,
//       "status": "active",
//       "createdAt": {
//         "$date": "2024-11-05T16:45:14.651Z"
//       },
//       "updatedAt": {
//         "$date": "2024-11-05T16:45:30.601Z"
//       },
//       "__v": 0
//     },
//     {
//       "_id": {
//         "$oid": "672b43da3e5a231f099c8bcc"
//       },
//       "email": "tyagihimanshi@gmail.com",
//       "traderId": "TR123456",
//       "username": "user123",
//       "password": "$2b$10$AFaFoYXZGF./NH5uxssOsOYFmKvvWJFO9A9i6m7I3FT0hy72Wfe4a",
//       "role": "user",
//       "otp": "739957",
//       "isOtpVerified": false,
//       "status": "pending",
//       "createdAt": {
//         "$date": "2024-11-06T10:24:26.194Z"
//       },
//       "updatedAt": {
//         "$date": "2024-11-06T14:43:02.317Z"
//       },
//       "__v": 0,
//       "verifiedByAdmin": true
//     },
//     {
//       "_id": {
//         "$oid": "672b7443bcdb6b0a7b097146"
//       },
//       "email": "tyagihimanshi241@gmail.com",
//       "traderId": "T12345",
//       "username": "newuser",
//       "password": "$2b$10$X5q3oCrtkzbyR9seoIue2.EbXUUEzCClVpPq.QFxMjYcZWs.NPXkW",
//       "role": "user",
//       "isOtpVerified": true,
//       "verifiedByAdmin": true,
//       "createdAt": {
//         "$date": "2024-11-06T13:50:59.757Z"
//       },
//       "updatedAt": {
//         "$date": "2024-11-06T13:52:17.670Z"
//       },
//       "__v": 0
//     }
//   ];

//   const handleApprove = async (userId) => {
//     try {
//       const baseUrl = process.env.REACT_APP_BASE_URL;
//       const response = await axios.put(`${baseUrl}/api/auth/verify-by-admin/${userId}`);
//       if (response.data.message === "User verified by admin successfully") {
//         console.log(`User with ID: ${userId} verified by admin.`);
//         console.log(response.data)
//         console.log(`${baseUrl}/api/auth/verify-by-admin/${userId}`)
//       }
//     } catch (error) {
//       console.error('Error verifying user by admin:', error);
//     }
//   };

//   const handleDeny = (traderId) => {
//     console.log(`Denied trader with ID: ${traderId}`);
//   };

//   const filteredData = data.filter(item =>
//     item.username.toLowerCase().includes(search.toLowerCase()) &&
//     (statusFilter === 'All' || item.status === statusFilter)
//   );

//   const customStyles = {
//     header: {
//       style: {
//         minHeight: '56px',
//         backgroundColor: '#f1f3f5',
//         color: '#343a40',
//         fontSize: '18px',
//         fontWeight: 'bold',
//       },
//     },
//     headRow: {
//       style: {
//         backgroundColor: '#f1f3f5',
//         borderBottomWidth: '2px',
//         borderBottomColor: '#dee2e6',
//         borderBottomStyle: 'solid',
//       },
//     },
//     headCells: {
//       style: {
//         color: '#343a40',
//         fontWeight: 'bold',
//         fontSize: '18px',
//         paddingLeft: '16px',
//         paddingRight: '16px',
//       },
//     },
//     rows: {
//       style: {
//         minHeight: '48px',
//         '&:not(:last-of-type)': {
//           borderBottomWidth: '1px',
//           borderBottomColor: '#dee2e6',
//           borderBottomStyle: 'solid',
//         },
//       },
//     },
//     cells: {
//       style: {
//         paddingLeft: '16px',
//         paddingRight: '16px',
//         fontSize: '16px',
//       },
//     },
//     pagination: {
//       style: {
//         borderTopWidth: '1px',
//         borderTopColor: '#dee2e6',
//         borderTopStyle: 'solid',
//         backgroundColor: '#f1f3f5',
//       },
//     },
//   };

//   return (
//     <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto' }}>
//       <h1 className='font-bold text-[24px] leading-[24px] text-[#23272E] mt-16 md:mt-0'>Forexyy User-Approval</h1>
//       <div className="flex flex-col md:flex-row justify-between mb-5 gap-3 mt-8">
//         <div className="bg-slate-100 hover:bg-white border border-gray-300 rounded-full px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 w-3/4 md:w-auto">
//           <select
//             value={statusFilter}
//             onChange={(e) => setStatusFilter(e.target.value)}
//             className="bg-slate-100 hover:bg-white rounded-lg py-3 px-5 md:px-1 text-sm text-gray-700 focus:outline-none"
//           >
//             <option value="All">Filter by date range</option>
//             <option value="Pending">Pending</option>
//             <option value="Approved">Approved</option>
//             <option value="Denied">Denied</option>
//           </select>
//         </div>

//         <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//           <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//             <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//               <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//               <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//             </svg>
//           </div>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//           />
//         </div>
//       </div>

//       <DataTable
//         columns={columns}
//         data={filteredData}
//         pagination
//         highlightOnHover
//         pointerOnHover
//         dense
//         customStyles={customStyles}
//       />
//     </div>
//   );
// };

// export default Table1;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './Subadmin.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Table1 = () => {
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [data, setData] = useState([]);
  const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);
  const [denyReason, setDenyReason] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);


  const fetchUsers = async () => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.get(`${baseUrl}/api/auth/users`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  fetchUsers();


  const columns = [
    {
      name: 'TRADER ID',
      selector: row => row.traderId,
      sortable: true,
    },
    {
      name: 'NAME',
      selector: row => row.username,
      sortable: true,
    },
    {
      name: 'EMAIL',
      selector: row => row.email,
      sortable: true,
      grow: 0,
      width: '300px',
    },
    {
      name: 'STATUS',
      selector: row => row.status,
      cell: row => (
        <span
          className={
            row.status === 'PENDING'
              ? 'text-orange-500'
              : row.status === 'APPROVED'
                ? 'text-green-500'
                : 'text-red-500'
          }
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'ACTION',
      cell: row => (
        <div className="flex space-x-2 justify-center md:my-2 my-1">
          <button
            className="px-0 md:px-2 w-32 py-1 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={() => handleApprove(row._id)}
          >
            Approve
          </button>
          <button
            className="px-0 md:px-2 w-28 py-1 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => openDenyModal(row._id)}
          >
            Deny
          </button>
        </div>
      ),
      width: '280px',
    }
  ];

  const handleDeny = async () => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.put(`${baseUrl}/api/auth/deny-by-admin/${selectedUserId}`, { reason: denyReason });
      if (response.data.message === "User denied by admin successfully") {
        console.log(`User with ID: ${selectedUserId} denied by admin.`);
        toast.success("User Denied successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
        closeDenyModal();
        fetchUsers();
      }
    } catch (error) {
      toast.success("Error denying user by admin:", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    }
  };

  const handleApprove = async (userId) => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.put(`${baseUrl}/api/auth/verify-by-admin/${userId}`);
      if (response.data.message === "User verified by admin successfully") {
        console.log(`User with ID: ${userId} verified by admin.`);
        toast.success("User verified successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
        fetchUsers();
      }
    } catch (error) {
      toast.error('Error verifying user by admin:', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    }
  };

  const openDenyModal = (userId) => {
    setSelectedUserId(userId);
    setIsDenyModalOpen(true);
  };

  const closeDenyModal = () => {
    setIsDenyModalOpen(false);
    setDenyReason('');
  };

  const filteredData = data.filter(item =>
    item.username.toLowerCase().includes(search.toLowerCase()) &&
    (statusFilter === 'All' || item.status === statusFilter)
  );

  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
        backgroundColor: '#f1f3f5',
        color: '#343a40',
        fontSize: '18px',
        fontWeight: 'bold',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f1f3f5',
        borderBottomWidth: '2px',
        borderBottomColor: '#dee2e6',
        borderBottomStyle: 'solid',
      },
    },
    headCells: {
      style: {
        color: '#343a40',
        fontWeight: 'bold',
        fontSize: '18px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    rows: {
      style: {
        minHeight: '48px',
        '&:not(:last-of-type)': {
          borderBottomWidth: '1px',
          borderBottomColor: '#dee2e6',
          borderBottomStyle: 'solid',
        },
      },
    },
    cells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
        fontSize: '16px',
      },
    },
    pagination: {
      style: {
        borderTopWidth: '1px',
        borderTopColor: '#dee2e6',
        borderTopStyle: 'solid',
        backgroundColor: '#f1f3f5',
        minHeight: 'auto'
      },
    },
  };

  const laptopPaginationStyles = `
    @media (min-width: 1024px) {
        .rdt_Pagination {
            min-height: 56px;
        }
    }
`;

  return (
    <>
      <div className='min-h-[91vh] relative pt-1' style={{ backgroundImage: 'url(bg.svg)' }}>
        <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', marginTop: "10px" }}>
          <style>{laptopPaginationStyles}</style>
          <h1 className='font-bold text-[24px] leading-[24px] text-[#23272E] mt-16 md:mt-0'>Forexyy User-Approval</h1>
          <div className="flex flex-col md:flex-row justify-between mb-5 gap-3 mt-8">
            <div className="bg-slate-100 hover:bg-white border border-gray-300 rounded-full px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 w-3/4 md:w-auto">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="bg-slate-100 hover:bg-white rounded-lg py-3 px-5 md:px-1 text-sm text-gray-700 focus:outline-none"
              >
                <option value="All">Filter by status</option>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="DENIED">Denied</option>
              </select>
            </div>

            <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
              <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
                  <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
              />
            </div>
          </div>

          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            pointerOnHover
            dense
            customStyles={customStyles}
          />
          {/* Deny Reason Modal */}
          <Modal
            open={isDenyModalOpen}
            onClose={closeDenyModal}
            center
            styles={{
              modal: {
                width: '80%',
                maxWidth: '80%',
              },
            }}
          >
            <h2>Deny User</h2>
            <p>Please provide a reason for denying this user:</p>
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              value={denyReason}
              onChange={(e) => setDenyReason(e.target.value)}
              rows="4"
            ></textarea>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={closeDenyModal}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDeny}
              >
                Deny User
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Table1;
