// import React, { useState} from 'react';
// import DataTable from 'react-data-table-component';
// import './Subadmin.css';
// import RoleEditModal from './RoleEditModal';

// const Table1 = () => {
//     const [search, setSearch] = useState('');
//     const [isRoleEditModalOpen, setIsRoleEditModalOpen] = useState(false);
//     const [selectedSubAdmin, setSelectedSubAdmin] = useState(null);

//     const handleEditRoleClick = (subAdmin) => {
//         setSelectedSubAdmin(subAdmin);
//         setIsRoleEditModalOpen(true);
//     };

//     const handleRoleEditClose = () => {
//         setIsRoleEditModalOpen(false);
//         setSelectedSubAdmin(null); 
//     };

//     const handleSaveRoles = (updatedRoles) => {
//         console.log('Updated Roles:', updatedRoles);
//         handleRoleEditClose();
//     };

//     const columns = [
//         {
//             name: 'NAME',
//             selector: row => row.name,
//             sortable: true,
//         },
//         {
//           name: 'SUBADMINID',
//           selector: row => row.subadminid,
//           sortable: true,
//           grow: 0, 
//           width: '180px',
//       },
//       {
//         name: 'DEPARTMENT',
//         selector: row => row.Department,
//         sortable: true,
//         grow: 0, 
//         width: '180px',
//     },
//       {
//           name: 'EMAIL',
//           selector: row => row.email,
//           sortable: true,
//           grow: 0, 
//           width: '300px', 
//       },
//         {
//             name: 'PHONE NO.',
//             selector: row => row.phone,
//             sortable: true,
//             grow: 0,
//           width: '200px',
//         },
//         {
//             name: 'ACTION',
//             cell: row => (
//                 <div className="flex space-x-2 justify-center py-3">
//                     <button
//                         onClick={() => handleEditRoleClick(row)} 
//                         className="bg-blue-500 text-white px-4 py-2 rounded-md font-semibold shadow-md hover:bg-blue-600 hover:shadow-lg transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
//                     >
//                         Edit Role
//                     </button>
//                 </div>
//             ),
//         }
//     ];

//     const data = [
//       {
//           "_id": { "$oid": "6726aec7630aba2ce8f165b4" },
//           "name": "Ayush prashant",
//           "subadminid": "Ayush@123",
//           "email": "ayushprashant12345@gmail.com",
//           "phone": "9905382798",
//           "Department": "Computer",
//           "password": "securePassword123",
//           "__v": 0
//       },
//       {
//         "_id": { "$oid": "6726aec7630aba2ce8f165b4" },
//         "name": "Ayush ",
//         "subadminid": "Ayush@123",
//         "email": "ayushprashant12345@gmail.com",
//         "phone": "9905382798",
//         "Department": "Computer",
//         "password": "securePassword123",
//         "__v": 0
//     },
//     {
//         "_id": { "$oid": "6726aec7630aba2ce8f165b4" },
//         "name": "Muskan",
//         "subadminid": "Ayush@123",
//         "email": "ayushprashant12345@gmail.com",
//         "phone": "9905382798",
//         "Department": "Computer",
//         "password": "securePassword123",
//         "__v": 0
//     },
//   ]


//     const filteredData = data.filter(item =>
//         item.name.toLowerCase().includes(search.toLowerCase())
//     );

//     const customStyles = {
//         header: {
//             style: {
//                 minHeight: '56px',
//                 backgroundColor: '#f1f3f5',
//                 color: '#343a40',
//                 fontSize: '18px',
//                 fontWeight: 'bold',
//             },
//         },
//         headRow: {
//             style: {
//                 backgroundColor: '#f1f3f5',
//                 borderBottomWidth: '2px',
//                 borderBottomColor: '#dee2e6',
//                 borderBottomStyle: 'solid',
//             },
//         },
//         headCells: {
//             style: {
//                 color: '#343a40',
//                 fontWeight: 'bold',
//                 fontSize: '18px',
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//             },
//         },
//         rows: {
//             style: {
//                 minHeight: '48px',
//                 '&:not(:last-of-type)': {
//                     borderBottomWidth: '1px',
//                     borderBottomColor: '#dee2e6',
//                     borderBottomStyle: 'solid',
//                 },
//             },
//         },
//         cells: {
//             style: {
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//                 fontSize: '16px',
//             },
//         },
//         pagination: {
//             style: {
//                 borderTopWidth: '1px',
//                 borderTopColor: '#dee2e6',
//                 borderTopStyle: 'solid',
//                 backgroundColor: '#f1f3f5',
//             },
//         },
//     };

//     return (
//         <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', }}>
//             <div className="flex justify-end mb-5 md:mb-3 mt-16 md:mt-0">
//                 <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//                     <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//                             <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                             <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                         </svg>
//                     </div>
//                     <input
//                         type="text"
//                         placeholder="Search..."
//                         value={search}
//                         onChange={e => setSearch(e.target.value)}
//                         className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//                     />
//                 </div>
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredData}
//                 pagination
//                 highlightOnHover
//                 pointerOnHover
//                 dense
//                 customStyles={customStyles}
//             />
//             <RoleEditModal
//                 isOpen={isRoleEditModalOpen}
//                 onClose={handleRoleEditClose}
//                 subAdmin={selectedSubAdmin}
//                 onSave={handleSaveRoles}
//             />
//         </div>
//     );
// };

// export default Table1;

// import React, { useState, useEffect } from 'react';
// import DataTable from 'react-data-table-component';
// import axios from 'axios'; // Import axios for API calls
// import './Subadmin.css';
// import RoleEditModal from './RoleEditModal';

// const Table1 = () => {
//     const [search, setSearch] = useState('');
//     const [isRoleEditModalOpen, setIsRoleEditModalOpen] = useState(false);
//     const [selectedSubAdmin, setSelectedSubAdmin] = useState(null);
//     const [subAdmins, setSubAdmins] = useState([]); // State to store sub-admin data

//     // Fetch subadmins when the component mounts
//     // useEffect(() => {
//         axios.get('https://webai-forex-backend-ashy.vercel.app/api/departments/subadmins') // Adjust the URL as needed
//             .then(response => {
//                 setSubAdmins(response.data); // Store the response data in state
//             })
//             .catch(error => {
//                 console.error('Error fetching subadmins:', error);
//             });
//     // }, []);

//     const handleEditRoleClick = (subAdmin) => {
//         setSelectedSubAdmin(subAdmin);
//         setIsRoleEditModalOpen(true);
//     };

//     const handleRoleEditClose = () => {
//         setIsRoleEditModalOpen(false);
//         setSelectedSubAdmin(null); 
//     };

//     const handleSaveRoles = (updatedRoles) => {
//         console.log('Updated Roles:', updatedRoles);
//         handleRoleEditClose();
//     };

//     const columns = [
//         {
//             name: 'NAME',
//             selector: row => row.name,
//             sortable: true,
//         },
//         {
//             name: 'SUBADMINID',
//             selector: row => row.subAdminId,
//             sortable: true,
//             grow: 0, 
//             width: '220px',
//         },
//         {
//             name: 'DEPARTMENT',
//             selector: row => row.departmentName,
//             sortable: true,
//             grow: 0, 
//             width: '180px',
//         },
//         {
//             name: 'EMAIL',
//             selector: row => row.email,
//             sortable: true,
//             grow: 0, 
//             width: '300px', 
//         },
//         {
//             name: 'PHONE NO.',
//             selector: row => row.phone,
//             sortable: true,
//             grow: 0,
//             width: '200px',
//         },
//         {
//             name: 'ACTION',
//             cell: row => (
//                 <div className="flex space-x-2 justify-center py-3">
//                     <button
//                         onClick={() => handleEditRoleClick(row)} 
//                         className="bg-blue-500 text-white px-4 py-2 rounded-md font-semibold shadow-md hover:bg-blue-600 hover:shadow-lg transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
//                     >
//                         Edit Role
//                     </button>
//                 </div>
//             ),
//         }
//     ];

//     const filteredData = subAdmins.filter(item =>
//         item.name.toLowerCase().includes(search.toLowerCase())
//     );

//     const customStyles = {
//         header: {
//             style: {
//                 minHeight: '56px',
//                 backgroundColor: '#f1f3f5',
//                 color: '#343a40',
//                 fontSize: '18px',
//                 fontWeight: 'bold',
//             },
//         },
//         headRow: {
//             style: {
//                 backgroundColor: '#f1f3f5',
//                 borderBottomWidth: '2px',
//                 borderBottomColor: '#dee2e6',
//                 borderBottomStyle: 'solid',
//             },
//         },
//         headCells: {
//             style: {
//                 color: '#343a40',
//                 fontWeight: 'bold',
//                 fontSize: '18px',
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//             },
//         },
//         rows: {
//             style: {
//                 minHeight: '48px',
//                 '&:not(:last-of-type)': {
//                     borderBottomWidth: '1px',
//                     borderBottomColor: '#dee2e6',
//                     borderBottomStyle: 'solid',
//                 },
//             },
//         },
//         cells: {
//             style: {
//                 paddingLeft: '16px',
//                 paddingRight: '16px',
//                 fontSize: '16px',
//             },
//         },
//         pagination: {
//             style: {
//                 borderTopWidth: '1px',
//                 borderTopColor: '#dee2e6',
//                 borderTopStyle: 'solid',
//                 backgroundColor: '#f1f3f5',
//             },
//         },
//     };

//     return (
//         <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto' }}>
//             <div className="flex justify-end mb-5 md:mb-3 mt-16 md:mt-0">
//                 <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
//                     <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
//                             <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                             <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                         </svg>
//                     </div>
//                     <input
//                         type="text"
//                         placeholder="Search..."
//                         value={search}
//                         onChange={e => setSearch(e.target.value)}
//                         className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
//                     />
//                 </div>
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredData}
//                 pagination
//                 highlightOnHover
//                 pointerOnHover
//                 dense
//                 customStyles={customStyles}
//             />
//             <RoleEditModal
//                 isOpen={isRoleEditModalOpen}
//                 onClose={handleRoleEditClose}
//                 subAdmin={selectedSubAdmin}
//                 onSave={handleSaveRoles}
//             />
//         </div>
//     );
// };

// export default Table1;


import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import './Subadmin.css';
import RoleEditModal from './RoleEditModal';
import { FiUserX, FiUnlock, FiEdit, FiTrash2 } from 'react-icons/fi';


const Table1 = () => {
    const [search, setSearch] = useState('');
    const [isRoleEditModalOpen, setIsRoleEditModalOpen] = useState(false);
    const [selectedSubAdmin, setSelectedSubAdmin] = useState(null);
    const [subAdmins, setSubAdmins] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments/subadmins`)
            .then(response => {
                setSubAdmins(response.data);
                console.log(response.data)
            })
            .catch(error => {
                console.error('Error fetching subadmins:', error);
            });
    }, []);

    const handleEditRoleClick = (subAdmin) => {
        setSelectedSubAdmin(subAdmin);
        setIsRoleEditModalOpen(true);
    };

    const handleRoleEditClose = () => {
        setIsRoleEditModalOpen(false);
        setSelectedSubAdmin(null);
    };


    const handleSaveRoles = (updatedRoles) => {
        const roles = Object.keys(updatedRoles).filter(role => updatedRoles[role]);

        console.log('Roles being sent:', roles);
        const departmentId = selectedSubAdmin.departmentId;
        const subAdminId = selectedSubAdmin._id;

        axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/departments/${departmentId}/subadmin/${subAdminId}/assign-roles`,
            { roles }
        )
            .then(response => {
                console.log('Roles updated successfully:', response.data);
                handleRoleEditClose();
            })
            .catch(error => {
                console.error('Error updating roles:', error);
            });
    };
    
    const handleDeleteSubAdmin = async (subAdmin) => {
        console.log('Subadmin name: ', subAdmin)
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (confirmDelete) {
            try {
                // Make a DELETE request to the endpoint to delete the user
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/departments/subadmin/${subAdmin._id}`);
                // Update the state to remove the deleted user from the data
                await axios.get(`${process.env.REACT_APP_BASE_URL}/api/departments/subadmins`)
                    .then(response => {
                        setSubAdmins(response.data);
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.error('Error fetching subadmins:', error);
                    });
                alert("User deleted successfully");
            } catch (error) {
                console.error("Error deleting user:", error);
            }
        }
    }


    const columns = [
        {
            name: 'NAME',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'SUBADMINID',
            selector: row => row.subAdminId,
            sortable: true,
            grow: 0,
            width: '180px',
        },
        {
            name: 'DEPARTMENT',
            selector: row => row.departmentName,
            sortable: true,
            grow: 0,
            width: '180px',
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
            sortable: true,
            grow: 0,
            width: '300px',
        },
        {
            name: 'PHONE NO.',
            selector: row => row.phone,
            sortable: true,
            grow: 0,
            width: '200px',
        },
        {
            name: 'ACTION',
            // cell: row => (
            //     <div className="flex space-x-2 justify-center py-2">
            //         <button
            //             onClick={() => handleEditRoleClick(row)}
            //             className="bg-blue-500 text-white px-4 py-2 rounded-md font-semibold shadow-md hover:bg-blue-600 hover:shadow-lg transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 w-28 md:w-full"
            //         >
            //             Edit Role
            //         </button>
            //     </div>
            // ),
            cell: row => (
                <div className="flex space-x-4 justify-center py-4 px-3">
                    <button onClick={() => handleEditRoleClick(row)} title="Edit" className="text-blue-500 hover:text-blue-600 transition duration-200">
                        <FiEdit />
                    </button>
                    <button onClick={() => handleDeleteSubAdmin(row)} title="Delete" className="text-red-500 hover:text-red-600 transition duration-200">
                        <FiTrash2 />
                    </button>
                </div>
            ),
        }
    ];

    const filteredData = subAdmins.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    const customStyles = {
        header: {
            style: {
                minHeight: '56px',
                backgroundColor: '#f1f3f5',
                color: '#343a40',
                fontSize: '18px',
                fontWeight: 'bold',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#f1f3f5',
                borderBottomWidth: '2px',
                borderBottomColor: '#dee2e6',
                borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                color: '#343a40',
                fontWeight: 'bold',
                fontSize: '18px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        rows: {
            style: {
                minHeight: '48px',
                '&:not(:last-of-type)': {
                    borderBottomWidth: '1px',
                    borderBottomColor: '#dee2e6',
                    borderBottomStyle: 'solid',
                },
            },
        },
        cells: {
            style: {
                paddingLeft: '16px',
                paddingRight: '16px',
                fontSize: '16px',
            },
        },
        pagination: {
            style: {
                borderTopWidth: '1px',
                borderTopColor: '#dee2e6',
                borderTopStyle: 'solid',
                backgroundColor: '#f1f3f5',
                minHeight: 'auto'
            },
        },

    };

    const laptopPaginationStyles = `
    @media (min-width: 1024px) {
        .rdt_Pagination {
            min-height: 56px;
        }
    }
`;

    return (
        <>
            <div className='min-h-[91vh] relative pt-1' style={{ backgroundImage: 'url(bg.svg)' }}>
                <div className="dataTable-container" style={{ width: '100%', overflowX: 'auto', marginTop: "10px" }}>
                    <style>{laptopPaginationStyles}</style>
                    <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>SUB-ADMIN</h1>
                    <div className="flex justify-end mb-5 md:mb-5 mt-16 md:mt-0">
                        <div className="flex items-center bg-slate-100 hover:bg-white border border-gray-300 rounded-full md:py-1 w-[180px] md:w-auto pl-3 pr-3">
                            <div className="flex items-center justify-center w-10 h-10 hover:bg-white rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon nav-v2-search__icon">
                                    <path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                className="w-full pl-3 pr-3 py-2 text-sm text-gray-700 bg-slate-100 hover:bg-white border-0 focus:outline-none"
                            />
                        </div>
                    </div>

                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        pointerOnHover
                        dense
                        customStyles={customStyles}
                    />

                    <RoleEditModal
                        isOpen={isRoleEditModalOpen}
                        onClose={handleRoleEditClose}
                        subAdmin={selectedSubAdmin}
                        onSave={handleSaveRoles}
                    />
                </div>
            </div>
        </>
    );
};

export default Table1;
