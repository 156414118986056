import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import alex from '../Assets/alex.jpg'
import Avatar from '../Components/Avtar'
import axios from 'axios'

const UserSearchCard = ({ user, onClose }) => {
    const [participants, setParticipants] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://webai-forex-backend-ashy.vercel.app/api/chat/group/67290e562faba7bce90089d9")
                setParticipants(response.data.participants)
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        }
        fetchData()
    }, [])
    return (
        <div onClick={onClose} className='p-1 lg:p-4 border border-transparent border-b-slate-200 rounded cursor-pointer'>
            {
                // participants.map((user) => (
                //     <>
                //         <div>
                //             <Avatar
                //                 width={40}
                //                 height={40}
                //                 name={'Rudraksh'}
                //                 userId={1}
                //                 imageUrl={alex}
                //             />
                //         </div>
                //         <div>
                //             <div className='font-semibold text-ellipsis line-clamp-1'>
                //                 {user}
                //             </div>
                //         </div>
                //     </>
                // ))
                participants.map((user, index) => (
                    <div key={index} className='flex items-center gap-3 p-2 hover:border hover:border-primary rounded cursor-pointer'>
                        <div>
                            <Avatar
                                width={40}
                                height={40}
                                name={'Rudraksh'}
                                userId={1}
                                imageUrl={alex}
                            />
                        </div>
                        <div>
                            <div className='font-semibold text-ellipsis line-clamp-1'>
                                {user}
                            </div>
                        </div>
                    </div>
                ))

            }
        </div>
    )
}

export default UserSearchCard
