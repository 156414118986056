import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-modal/styles.css";
import Slider from "react-slick";
import AddNotification from "./AddNotification";
import NotificationIcon from '../Assets/NotificationsIcon.svg';
import { Bounce, toast, ToastContainer } from "react-toastify";

const Notifications = () => {
    const [analysisData, setAnalysisData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [isAddAnalysisModalOpen, setIsAddAnalysisModalOpen] = useState(false);

    const fetchAnalysisData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/notifications`
            );
            console.log('Notificaiotbs: ', response.data.notifications)
            setAnalysisData(response.data.notifications);
        } catch (error) {
            console.error("Error fetching analysis data:", error);
            setError("Failed to fetch analysis data");
        }
    };

    useEffect(() => {
        fetchAnalysisData();
    }, []);

    const handleReadMore = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
    };

    const openAddAnalysisModal = () => {
        setIsAddAnalysisModalOpen(true);
        setSelectedItem(null);
    };

    const closeAddAnalysisModal = () => {
        setIsAddAnalysisModalOpen(false);
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setIsAddAnalysisModalOpen(true);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this analysis?")) {
            try {
                const response = await axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}`
                );
                toastSuccess(response.data.message);
                fetchAnalysisData();
            } catch (error) {
                console.error("Error deleting analysis:", error);
                setError("Failed to delete analysis");
            }
        }
    };

    if (error) {
        return <div className="text-red-600">Error: {error}</div>;
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };
    const toastSuccess = (text) => toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const toastError = (text) => toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    return (
        <>
            <div className='min-h-[91vh] relative pt-1 overflow-y-auto' style={{ backgroundImage: 'url(bg.svg)' }}>
                <div className="p-5 md:px-8 md:mt-4 mt-16 mb-16 lg:mb-0">
                    <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-5'>NOTIFICATIONS</h1>
                    <div className="flex justify-end">
                        <button
                            onClick={openAddAnalysisModal}
                            className=" border border-[#B4CAF4] rounded-[15px] md:rounded-[6px] px-4 py-2 flex items-center hover:bg-[#0760FF] hover:bg-opacity-25 cursor-pointer mb-5"
                        >
                            <span className='text-[#0760FF] hover:text-blue-800 text-[15px] leading-[17.63px] p-2 font-medium hover:font-semibold cursor-pointer'>Add Notification</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3103 2.70044C12.6274 2.0173 11.6628 1.91016 10.5245 1.91016H5.46196C4.33711 1.91016 3.37282 2.0173 2.68968 2.70016C2.00654 3.38301 1.90625 4.34073 1.90625 5.4593V10.5216C1.90625 11.6667 2.00682 12.6176 2.68968 13.3007C3.37254 13.9839 4.33711 14.0907 5.48225 14.0907H10.5245C11.6628 14.0907 12.6274 13.9836 13.3103 13.3007C13.9931 12.6179 14.0937 11.6667 14.0937 10.5216V5.4793C14.0937 4.33416 13.9934 3.37673 13.3103 2.70044ZM7.99996 11.9276C7.85975 11.9274 7.72533 11.8716 7.62616 11.7725C7.52698 11.6733 7.47112 11.5389 7.47082 11.3987V8.52616H4.60511C4.31025 8.52616 4.07596 8.28501 4.07596 8.00387C4.07596 7.72273 4.31025 7.46816 4.60482 7.46816H7.47082V4.60187C7.47082 4.30073 7.70511 4.06616 7.99996 4.06616C8.30139 4.06616 8.52911 4.30044 8.52911 4.60187V7.46816H11.4017C11.6965 7.46816 11.9308 7.72244 11.9308 8.00387C11.9308 8.28501 11.6965 8.52616 11.4017 8.52616H8.52911V11.399C8.52953 11.4686 8.51612 11.5376 8.48967 11.602C8.46323 11.6663 8.42426 11.7248 8.37504 11.774C8.32582 11.8232 8.26732 11.8621 8.20293 11.8885C8.13854 11.915 8.06956 11.928 7.99996 11.9276Z" fill="#04CF31" />
                            </svg>
                        </button>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="grid grid-cols-1 gap-4">
                            {analysisData.map((item) => (
                                <div
                                    key={item._id}
                                    className="bg-[#EBEBEB] p-3 rounded-lg shadow flex flex-wrap md:flex-nowrap gap-4 hover:shadow-md transition-shadow relative"
                                >
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        className="w-full md:max-w-[160px] h-40 object-cover rounded-md"
                                    />
                                    <div>
                                        <p className="text-sm text-gray-600 font-display mb-6">
                                            {item.message}
                                        </p>
                                    </div>
                                    <div className="absolute bottom-4 right-4 flex space-x-2">
                                        <button onClick={() => handleDelete(item._id)} aria-label="Delete">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 28 28"
                                                fill="currentColor"
                                                className="w-7 h-7 text-red-600 hover:text-red-800"
                                            >
                                                <path d="M3 6h18v2H3zm3 3h12v12H6z" />
                                            </svg>
                                        </button>
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            theme="light"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* {analysisData.map((item) => (
                            <div
                                key={item._id}
                                className="bg-[#EBEBEB] p-5 rounded-lg shadow flex flex-wrap md:flex-nowrap gap-4 hover:shadow-md transition-shadow relative"
                            >
                                <img
                                    src={item.images}
                                    alt={item.title}
                                    className="w-full md:max-w-[216px] h-44 object-cover rounded-md"
                                />
                                <div>
                                    <h3 className="text-base font-display font-semibold text-gray-800 mb-3">
                                        {item.title}
                                    </h3>
                                    <p className="text-sm text-gray-600 font-display mb-6">
                                        {item.message.slice(0, 85)}...
                                    </p>
                                </div>
                                <div className="absolute bottom-4 right-4 flex space-x-2">
                                    <button onClick={() => handleEdit(item)} aria-label="Edit">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 28 28"
                                            fill="currentColor"
                                            className="w-7 h-7 text-blue-600 hover:text-blue-800"
                                        >
                                            <path d="M5 20h14v2H5zM17.7 2.3c.4-.4 1-.4 1.4 0l2.6 2.6c.4.4.4 1 0 1.4l-13 13-4.2.8.8-4.2 13-13z" />
                                        </svg>
                                    </button>
                                    <button onClick={() => handleDelete(item._id)} aria-label="Delete">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 28 28"
                                            fill="currentColor"
                                            className="w-7 h-7 text-red-600 hover:text-red-800"
                                        >
                                            <path d="M3 6h18v2H3zm3 3h12v12H6z" />
                                        </svg>
                                    </button>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="light"
                                    />
                                </div>
                            </div>
                        ))}  */}
                    </div>
                    {selectedItem && (
                        <Modal open={isModalOpen} onClose={closeModal} center>
                            <div className="p-5 md:p-6 lg:p-8 max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl mx-auto bg-white rounded-xl overflow-hidden">
                                <Slider {...sliderSettings} className="mt-2">
                                    {selectedItem.images.map((img, index) => (
                                        <div key={index}>
                                            <img
                                                src={img}
                                                alt={`Slide ${index + 1}`}
                                                className="w-full h-48 sm:h-56 md:h-64 object-cover rounded-md"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                                <h2 className="text-base sm:text-lg md:text-xl font-semibold mt-6">
                                    {selectedItem.title}
                                </h2>
                                <p className="text-gray-700 mt-4 max-h-44 overflow-y-auto pr-2 text-sm sm:text-base">
                                    {selectedItem.description}
                                </p>
                            </div>
                        </Modal>
                    )}
                    <Modal
                        open={isAddAnalysisModalOpen}
                        onClose={closeAddAnalysisModal}
                        center
                        styles={{
                            modal: {
                                width: '80%',
                                maxWidth: '80%',
                            },
                        }}
                    >
                        <AddNotification
                            selectedItem={selectedItem}
                            fetchAnalysisData={fetchAnalysisData}
                            closeModal={closeAddAnalysisModal}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default Notifications;