import React, { useEffect, useState } from 'react'
import { IoSearchOutline } from "react-icons/io5";
import axios from 'axios';
import { IoClose } from "react-icons/io5";
import UserSearchCard from './UserSearchCard';

const SearchUser = ({ onClose }) => {
    const [searchUser, setSearchUser] = useState([])
    console.log("searchUser", searchUser)
    return (
        <div className='fixed top-0 bottom-0 left-0 right-0 bg-slate-700 bg-opacity-40 p-2 z-10'>
            <div className='w-full max-w-lg mx-auto mt-10'>
                <div className='bg-white w-full rounded h-full max-h-[60vh] overflow-scroll'>
                    <div className='bg-white rounded h-14 overflow-hidden flex '>
                        <h2 className='text-xl font-bold p-4 text-slate-800'>Users</h2>
                    </div>
                    <UserSearchCard onClose={onClose} />
                </div>
            </div>

            <div className='absolute top-0 right-0 text-2xl p-2 lg:text-4xl text-white' onClick={onClose}>
                <button>
                    <IoClose />
                </button>
            </div>
        </div>
    )
}

export default SearchUser
