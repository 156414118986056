import React, { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RoleEditModal = ({ isOpen, onClose, subAdmin, onSave }) => {
    const [roles, setRoles] = useState({
        Signal: false,
        department: false,
        analysis: false,
        courses: false,
        subAdmin: false,
        Members: false,
        verification: false,
        settings: false,
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setRoles(prevRoles => ({
            ...prevRoles,
            [name]: checked,
        }));
    };

    // const handleSave = () => {
    //     onSave(roles);
    //     onClose();
    // };

    const handleSave = () => {
        try {
            onSave(roles); 
            toast.success("Roles saved successfully!"); 
            onClose(); 
        } catch (error) {
            console.error("Error saving roles:", error);
            toast.error("Failed to save roles. Please try again.");
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="bg-white p-6 w-full max-w-lg mx-auto my-10 rounded-lg shadow-lg"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
            <ToastContainer />
            <h2 className="text-xl font-semibold mb-4">Edit Roles for {subAdmin?.name}</h2>
            <div className="space-y-3">
                {Object.keys(roles).map(role => (
                    <div key={role} className="flex items-center space-x-3">
                        <input
                            type="checkbox"
                            name={role}
                            checked={roles[role]}
                            onChange={handleCheckboxChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <label className="capitalize">{role.replace(/([A-Z])/g, ' $1')}</label>
                    </div>
                ))}
            </div>
            <div className="flex justify-end space-x-3 mt-6">
                <button
                    onClick={onClose}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSave}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    Save
                </button>
            </div>
        </Modal>
    );
};

export default RoleEditModal;
